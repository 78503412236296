import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import NavigationBar from './components/layout/NavigationBar';
import Aboutme from './components/content/Aboutme';
import Projects from './components/content/Projects';
import Contact from './components/content/Contact';
import Technologies from './components/content/Technologies';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavigationBar />
        <Aboutme />
        <Projects />
        <Technologies />
        <Contact />
      </div>
    </BrowserRouter>
  );
}

export default App;