import React from "react";
import { Button } from "react-mdl";
import { Container, Row, Col } from "react-bootstrap";
import messages from "../../assets/messages.png";
import "./Contact.css";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  componentDidMount() {
    if (this.multilineTextarea) {
      this.multilineTextarea.style.height = "auto";
    }
  }

  changeTextarea = () => {
    this.multilineTextarea.style.height = "auto";
    this.multilineTextarea.style.height =
      this.multilineTextarea.scrollHeight + "px";
  };

  render() {
    const { status } = this.state;
    return (
      <section className="contact" id="contact">
        <div className="projects-spacing"></div>
        <Container>
          <Row>
            <h4 className="font-style"> &lt;Contact&gt;</h4>
          </Row>
          <Row className="contact-card">
            {/* Flex item 1 */}
            <Col md>
              <Row>
                <img
                  alt="contact folders"
                  className="message-img"
                  src={messages}
                ></img>
              </Row>

              <Row>
                <Col md>
                  <div className="social space" style={{ textAlign: "center" }}>
                    <a href="mailto:ionpetro@gmail.com">
                      <i class="fas fa-envelope fa-2x getEmail"></i>
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/in/ion-petropoulos-081a34130/"
                    >
                      <i class="fab fa-linkedin-in fa-2x"></i>
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.github.com/ionpetro"
                    >
                      <i class="fab fa-github fa-2x"></i>
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://twitter.com/ionpetropoulos"
                    >
                      <i class="fab fa-twitter fa-2x"></i>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={1}></Col>

            {/* Flex item 2 */}
            <Col md>
              <Row>
                <h2 className="font-style">Let's talk!</h2>
                <br></br>
              </Row>
              <Row>
                <h5>Fill in the form and let me get back to you!</h5>
              </Row>
              <Row>
                <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/xzbjqyaz"
                  method="POST"
                >
                  <input type="text" name="name" />
                  <label>Name</label>
                  <input type="email" name="email" />
                  <label>Email</label>
                  <textarea
                    type="text"
                    name="textarea"
                    onChange={this.changeTextarea}
                    rows="4"
                    className="textarea"
                    ref={(ref) => (this.multilineTextarea = ref)}
                  />
                  <label>Message</label>
                  <br></br>
                  {status === "SUCCESS" ? (
                    <p className="form-messages">
                      Thanks for your{" "}
                      <span role="img" aria-label="folder">
                        {" "}
                        ✉️
                      </span>
                      ! I will get back to you soon.
                    </p>
                  ) : (
                    <Button className="family">
                      <strong>SUBMIT</strong>
                    </Button>
                  )}
                  {status === "ERROR" && (
                    <p className="form-messages">
                      <span role="img" aria-label="warning">
                        ⚠️
                      </span>{" "}
                      Ooops! There was an error.
                    </p>
                  )}
                </form>
              </Row>
            </Col>
          </Row>

          <Row>
            <h4 className="font-style"> &lt;\Contact&gt;</h4>
          </Row>
        </Container>
        <footer>
          {" "}
          <p>&copy; Copyright 2021, ionpetro </p>{" "}
        </footer>
      </section>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
