import React, { Component } from "react";
import { Button } from "react-mdl";
import "./Aboutme.css";
import "./Svg.css";
import { Container, Row, Col } from "react-bootstrap";
import animate from "./../../lib/animate";

class Aboutme extends Component {
  constructor(props) {
    super(props);
    this.state = { copyClicked: false };
    this.copyMail = this.copyMail.bind(this);
  }
  componentDidMount() {
    animate();
  }

  copyMail() {
    navigator.clipboard.writeText("ionpetro@gmail.com");
    this.setState((state) => ({
      copyClicked: true,
    }));
    setInterval(() => {
      this.setState((state) => ({
        copyClicked: false,
      }));
    }, 1500);
  }

  render() {
    return (
      <div id="about" className="about">
        <Container sm={12}>
          <Row className="align-items-center justify-content-md-center">
            <Col className="name" xs={12} md>
              <h1 className="font-style">Hi! I'm Ion Petropoulos</h1>
              <h5>
                When an idea comes to my mind, <br></br> I design, develop and
                analyze it.
              </h5>
              <div className="social space">
                <span onClick={this.copyMail} className="copySpan">
                  <i className="fas fa-envelope fa-2x"></i>
                  <span className="tooltiptext">
                    {this.state.copyClicked ? "📧 copied!" : "Copy my 📧!"}
                  </span>
                </span>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/ion-petropoulos-081a34130/"
                >
                  <i class="fab fa-linkedin-in fa-2x"></i>
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.github.com/ionpetro"
                >
                  <i class="fab fa-github fa-2x"></i>
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/ionpetropoulos"
                >
                  <i class="fab fa-twitter fa-2x"></i>
                </a>
              </div>
              <div className="mb-2">
                <Button
                  onClick={toProjects}
                  className="family"
                  raised
                  ripple
                  size="lg"
                >
                  Check my projects
                </Button>
              </div>
            </Col>
            <Col xs={12} md>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 636.3 553.7"
              >
                <g id="Floor">
                  <path
                    id="Floor_10_"
                    class="st0"
                    d="M498.2,292.9c87.6,48.5,87.6,127.1,0,175.6c-87.6,48.5-229.5,48.5-317.1,0
		c-87.6-48.5-87.6-127.1,0-175.6C268.7,244.4,410.7,244.4,498.2,292.9z"
                  />
                </g>
                <g id="Shadows_1_">
                  <path
                    class="st1"
                    d="M541.5,361.5l-125,72.2c-0.9,0.5-2.4,0.5-3.4,0l-41-23.7l-80.8-46.6l-58.4-33.7c-0.9-0.5-0.9-1.4,0-1.9
		l125-72.2c0.9-0.5,2.4-0.5,3.4,0l180.2,104C542.4,360.1,542.4,360.9,541.5,361.5z"
                  />
                  <path
                    id="Shadow_6_"
                    class="st1"
                    d="M294.1,461.4c29.3-16.9,29.3-44.4,0-61.3c-29.3-16.9-76.9-16.9-106.2,0
		c-29.3,16.9-29.3,44.4,0,61.3C217.3,478.3,264.8,478.3,294.1,461.4z"
                  />
                  <path
                    class="st1"
                    d="M372.1,410c-22.6,7.6-52.1,5.8-71.4-5.4c-19.4-11.2-22.5-28.2-9.3-41.2L372.1,410z"
                  />
                  <path
                    id="Shadow_3_"
                    class="st1"
                    d="M508.1,441.3c14.5-8.4,14.5-21.9,0-30.3c-14.5-8.4-38-8.4-52.5,0c-14.5,8.4-14.5,21.9,0,30.3
		C470.2,449.7,493.6,449.7,508.1,441.3z"
                  />
                </g>
                <g id="Desk_1_">
                  <g id="Desk">
                    <g id="Table_2_">
                      <g id="XMLID_3957_">
                        <path
                          id="XMLID_3960_"
                          class="st2"
                          d="M419.2,304.7v110.9c0,0.7-0.5,1.6-1.1,1.9l-2.4,1.4c-0.6,0.4-1.6,0.4-2.2,0l-2.4-1.4
					c-0.6-0.4-1.1-1.2-1.1-1.9V304.7c0-0.7,0.5-1,1.1-0.6l2.4,1.4c0.6,0.4,1.6,0.4,2.2,0l2.4-1.4C418.7,303.7,419.2,304,419.2,304.7
					z"
                        />
                        <path
                          id="XMLID_3959_"
                          class="st3"
                          d="M413.5,418.9l-2.4-1.4c-0.6-0.4-1.1-1.2-1.1-1.9V304.7c0-0.7,0.5-1,1.1-0.6l2.4,1.4
					c0.6,0.4,1.1,1.2,1.1,1.9l0,110.9C414.6,419,414.1,419.3,413.5,418.9z"
                        />
                      </g>
                      <g id="XMLID_3954_">
                        <path
                          id="XMLID_3956_"
                          class="st2"
                          d="M520.7,246V357c0,0.7-0.5,1.6-1.1,1.9l-2.4,1.4c-0.6,0.4-1.6,0.4-2.2,0l-2.4-1.4
					c-0.6-0.4-1.1-1.2-1.1-1.9V246c0-0.7,0.5-1,1.1-0.6l2.4,1.4c0.6,0.4,1.6,0.4,2.2,0l2.4-1.4C520.2,245,520.7,245.3,520.7,246z"
                        />
                        <path
                          id="XMLID_3955_"
                          class="st3"
                          d="M515,360.3l-2.4-1.4c-0.6-0.4-1.1-1.2-1.1-1.9V246c0-0.7,0.5-1,1.1-0.6l2.4,1.4
					c0.6,0.4,1.1,1.2,1.1,1.9l0,110.9C516.1,360.4,515.6,360.6,515,360.3z"
                        />
                      </g>
                      <g id="XMLID_3951_">
                        <path
                          id="XMLID_3953_"
                          class="st2"
                          d="M282.5,225.8v110.9c0,0.7-0.5,1.6-1.1,1.9L279,340c-0.6,0.4-1.6,0.4-2.2,0l-2.4-1.4
					c-0.6-0.4-1.1-1.2-1.1-1.9V225.8c0-0.7,0.5-1,1.1-0.6l2.4,1.4c0.6,0.4,1.6,0.4,2.2,0l2.4-1.4C282,224.8,282.5,225.1,282.5,225.8
					z"
                        />
                        <path
                          id="XMLID_3952_"
                          class="st3"
                          d="M276.8,340l-2.4-1.4c-0.6-0.4-1.1-1.2-1.1-1.9V225.8c0-0.7,0.5-1,1.1-0.6l2.4,1.4
					c0.6,0.4,1.1,1.2,1.1,1.9l0,110.9C277.9,340.1,277.4,340.4,276.8,340z"
                        />
                      </g>
                      <g id="XMLID_3938_">
                        <g id="XMLID_3949_">
                          <path
                            id="XMLID_3950_"
                            class="st4"
                            d="M542.8,253.9v-0.8c0-1.3-0.9-2.9-2-3.5l-179-103.3c-1.1-0.6-3-0.6-4.1,0l-110.1,63.6
						c-1.1,0.6-2,2.2-2,3.5v0.8c0,1.3,0.9,2.9,2,3.5l179,103.3c1.1,0.6,3,0.6,4.1,0l110.1-63.6C541.9,256.8,542.8,255.2,542.8,253.9
						z"
                          />
                          <path
                            id="XMLID_339_"
                            class="st5"
                            d="M542.8,253.9v-0.8c0-1.3-0.9-2.9-2-3.5l-179-103.3c-1.1-0.6-3-0.6-4.1,0l-110.1,63.6
						c-1.1,0.6-2,2.2-2,3.5v0.8c0,1.3,0.9,2.9,2,3.5l179,103.3c1.1,0.6,3,0.6,4.1,0l110.1-63.6C541.9,256.8,542.8,255.2,542.8,253.9
						z"
                          />
                        </g>
                        <g id="XMLID_3947_">
                          <path
                            id="XMLID_3948_"
                            class="st4"
                            d="M428.6,316v5.5c0.7,0,1.5-0.2,2-0.5l110.1-63.6c1.1-0.7,2-2.2,2-3.5v-0.8
						c0-1.1-0.7-2.5-1.7-3.3c0.7,0.6,0.6,1.5-0.4,2.1l-110.1,63.6C430.1,315.9,429.4,316,428.6,316z"
                          />
                          <path
                            id="XMLID_3946_"
                            class="st6"
                            d="M428.6,316v5.5c0.7,0,1.5-0.2,2-0.5l110.1-63.6c1.1-0.7,2-2.2,2-3.5v-0.8
						c0-1.1-0.7-2.5-1.7-3.3c0.7,0.6,0.6,1.5-0.4,2.1l-110.1,63.6C430.1,315.9,429.4,316,428.6,316z"
                          />
                        </g>
                        <g>
                          <path
                            id="XMLID_3942_"
                            class="st4"
                            d="M540.8,251.9l-110.1,63.6c-1.1,0.6-2.9,0.6-4.1,0l-179-103.3c-1.1-0.6-1.1-1.7,0-2.4
						l110.1-63.6c1.1-0.6,3-0.6,4.1,0l179,103.3C541.9,250.2,541.9,251.3,540.8,251.9z"
                          />
                          <path
                            id="XMLID_337_"
                            class="st7"
                            d="M540.8,251.9l-110.1,63.6c-1.1,0.6-2.9,0.6-4.1,0l-179-103.3c-1.1-0.6-1.1-1.7,0-2.4
						l110.1-63.6c1.1-0.6,3-0.6,4.1,0l179,103.3C541.9,250.2,541.9,251.3,540.8,251.9z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="Keyboard_1_">
                      <path
                        id="Shadow_30_"
                        class="st8"
                        d="M415.1,250.5L389,265.7c-0.8,0.5-2.1,0.5-2.9,0L309,221.2c-0.8-0.5-0.8-1.2,0-1.7l26.1-15.1
				c0.8-0.5,2.1-0.5,2.9,0l77.1,44.4C415.9,249.3,415.9,250.1,415.1,250.5z"
                      />
                      <g id="Keyboard_4_">
                        <g id="XMLID_3931_">
                          <path
                            id="XMLID_3936_"
                            class="st9"
                            d="M310.6,216.8c0-0.3,0.2-0.6,0.6-0.8l24.5-14.1c0.8-0.5,2.1-0.5,2.9,0l74.8,43.3
						c0.4,0.2,0.6,0.5,0.6,0.8c0,0.3,0,2.3,0,2.6c0,0.3-0.2,0.6-0.6,0.8l-24.5,14.1c-0.8,0.5-2.1,0.5-2.9,0l-74.8-43.2
						c-0.4-0.2-0.6-0.5-0.6-0.8C310.6,219.2,310.6,217.1,310.6,216.8z"
                          />
                          <path
                            id="XMLID_3935_"
                            class="st2"
                            d="M388.9,260.9l24.5-14.1c0.8-0.5,0.8-1.2,0-1.7l-74.8-43.3c-0.8-0.5-2.1-0.5-2.9,0
						L311.2,216c-0.8,0.5-0.8,1.2,0,1.7l74.8,43.2C386.8,261.3,388.1,261.3,388.9,260.9z"
                          />
                          <path
                            id="XMLID_3933_"
                            class="st9"
                            d="M387.6,261.2v2.6c0.5,0,1-0.1,1.4-0.3l24.5-14.1c0.4-0.2,0.6-0.5,0.6-0.8v-2.6
						c0,0.3-0.2,0.6-0.6,0.8l-24.5,14.1C388.5,261.1,388,261.2,387.6,261.2z"
                          />
                        </g>
                        <path
                          id="XMLID_3768_"
                          class="st4"
                          d="M315.1,217C315.1,217,315.1,217,315.1,217l2.4-1.4l3.3,1.9l-2.4,1.4L315.1,217L315.1,217z
					 M318.9,214.8C318.9,214.8,318.9,214.8,318.9,214.8l2.4-1.4l2.6,1.5l-2.4,1.4L318.9,214.8L318.9,214.8z M319.7,219.7
					C319.7,219.7,319.7,219.7,319.7,219.7l2.4-1.4l2.6,1.5l-2.4,1.4L319.7,219.7L319.7,219.7z M322.7,212.6
					C322.7,212.6,322.7,212.6,322.7,212.6l2.4-1.4l4.2,2.4l-2.4,1.4L322.7,212.6L322.7,212.6z M322.8,217.1
					C322.8,217.1,322.8,217.1,322.8,217.1l2.4-1.4l2.1,1.2l-2.4,1.4L322.8,217.1L322.8,217.1z M323.6,221.9
					C323.6,221.9,323.6,221.9,323.6,221.9l2.4-1.4l2.6,1.5l-2.4,1.4L323.6,221.9L323.6,221.9z M326.2,219
					C326.2,219,326.2,219,326.2,219l2.4-1.4l2.1,1.2l-2.4,1.4L326.2,219L326.2,219z M326.5,210.5
					C326.5,210.4,326.5,210.4,326.5,210.5l2.4-1.4l3.3,1.9l-2.4,1.4L326.5,210.5L326.5,210.5z M327.5,224.2
					C327.5,224.2,327.5,224.1,327.5,224.2l2.4-1.4l18.7,10.8l-2.4,1.4L327.5,224.2L327.5,224.2z M328.2,215.8
					C328.2,215.8,328.2,215.8,328.2,215.8l2.4-1.4l2.1,1.2l-2.4,1.4L328.2,215.8L328.2,215.8z M329.5,221
					C329.5,220.9,329.5,220.9,329.5,221l2.4-1.4l2.1,1.2l-2.4,1.4L329.5,221L329.5,221z M330.3,208.3
					C330.3,208.2,330.3,208.2,330.3,208.3l2.4-1.4l2.1,1.2l-2.4,1.4L330.3,208.3L330.3,208.3z M331.1,213.1
					C331.1,213.1,331.1,213.1,331.1,213.1l2.4-1.4l2.1,1.2l-2.4,1.4L331.1,213.1L331.1,213.1z M331.6,217.8
					C331.6,217.7,331.6,217.7,331.6,217.8l2.4-1.4l2.1,1.2l-2.4,1.4L331.6,217.8L331.6,217.8z M332.9,222.9
					C332.9,222.9,332.9,222.9,332.9,222.9l2.4-1.4l2.1,1.2l-2.4,1.4L332.9,222.9L332.9,222.9z M333.7,210.2
					C333.7,210.2,333.7,210.2,333.7,210.2l2.4-1.4l2.1,1.2l-2.4,1.4L333.7,210.2L333.7,210.2z M334.5,215
					C334.5,215,334.5,215,334.5,215l2.4-1.4l2.1,1.2l-2.4,1.4L334.5,215L334.5,215z M334.9,219.7
					C334.9,219.7,334.9,219.7,334.9,219.7l2.4-1.4l2.1,1.2l-2.4,1.4L334.9,219.7L334.9,219.7z M334.9,205.6
					C334.9,205.6,334.9,205.6,334.9,205.6l2.4-1.4l2.6,1.5l-2.4,1.4L334.9,205.6L334.9,205.6z M336.3,224.8
					C336.3,224.8,336.3,224.8,336.3,224.8l2.4-1.4l2.1,1.2l-2.4,1.4L336.3,224.8L336.3,224.8z M337,212.1
					C337,212.1,337,212.1,337,212.1l2.4-1.4l2.1,1.2l-2.4,1.4L337,212.1L337,212.1z M337.8,217C337.8,217,337.8,217,337.8,217
					l2.4-1.4l2.1,1.2l-2.4,1.4L337.8,217L337.8,217z M338.3,221.6C338.3,221.6,338.3,221.6,338.3,221.6l2.4-1.4l2.1,1.2l-2.4,1.4
					L338.3,221.6L338.3,221.6z M339.6,226.8C339.6,226.8,339.6,226.8,339.6,226.8l2.4-1.4l2.1,1.2l-2.4,1.4L339.6,226.8L339.6,226.8
					z M340.3,208.7C340.3,208.7,340.3,208.7,340.3,208.7l2.4-1.4l2.1,1.2l-2.4,1.4L340.3,208.7L340.3,208.7z M340.4,214.1
					C340.4,214.1,340.4,214.1,340.4,214.1l2.4-1.4l2.1,1.2l-2.4,1.4L340.4,214.1L340.4,214.1z M341.2,218.9
					C341.2,218.9,341.2,218.9,341.2,218.9l2.4-1.4l2.1,1.2l-2.4,1.4L341.2,218.9L341.2,218.9z M341.6,223.6
					C341.6,223.6,341.7,223.5,341.6,223.6l2.4-1.4l2.1,1.2l-2.4,1.4L341.6,223.6L341.6,223.6z M343,228.7
					C343,228.7,343,228.7,343,228.7l2.4-1.4l2.1,1.2l-2.4,1.4L343,228.7L343,228.7z M343.6,210.6
					C343.6,210.6,343.6,210.6,343.6,210.6l2.4-1.4l2.1,1.2l-2.4,1.4L343.6,210.6L343.6,210.6z M343.8,216
					C343.8,216,343.8,216,343.8,216l2.4-1.4l2.1,1.2l-2.4,1.4L343.8,216L343.8,216z M344.6,220.9
					C344.6,220.9,344.6,220.8,344.6,220.9l2.4-1.4l2.1,1.2l-2.4,1.4L344.6,220.9L344.6,220.9z M345,225.5
					C345,225.5,345,225.5,345,225.5l2.4-1.4l2.1,1.2l-2.4,1.4L345,225.5L345,225.5z M346.3,230.7
					C346.3,230.6,346.3,230.6,346.3,230.7l2.4-1.4l2.1,1.2l-2.4,1.4L346.3,230.7L346.3,230.7z M347,212.6
					C347,212.6,347,212.5,347,212.6l2.4-1.4l2.1,1.2l-2.4,1.4L347,212.6L347,212.6z M347.1,218C347.1,217.9,347.1,217.9,347.1,218
					l2.4-1.4l2.1,1.2l-2.4,1.4L347.1,218L347.1,218z M347.5,235.7C347.5,235.7,347.5,235.7,347.5,235.7l2.4-1.4l2.6,1.5l-2.4,1.4
					L347.5,235.7L347.5,235.7z M347.9,222.8C347.9,222.8,347.9,222.8,347.9,222.8l2.4-1.4l2.1,1.2L350,224L347.9,222.8L347.9,222.8z
					 M348.4,227.4C348.4,227.4,348.4,227.4,348.4,227.4l2.4-1.4l2.1,1.2l-2.4,1.4L348.4,227.4L348.4,227.4z M349.7,232.6
					C349.7,232.6,349.7,232.6,349.7,232.6l2.4-1.4l2.1,1.2l-2.4,1.4L349.7,232.6L349.7,232.6z M350.4,214.5
					C350.4,214.5,350.4,214.5,350.4,214.5l2.4-1.4l2.1,1.2l-2.4,1.4L350.4,214.5L350.4,214.5z M350.5,219.9
					C350.5,219.9,350.5,219.9,350.5,219.9l2.4-1.4l2.1,1.2l-2.4,1.4L350.5,219.9L350.5,219.9z M351.3,224.7
					C351.3,224.7,351.3,224.7,351.3,224.7l2.4-1.4l2.1,1.2l-2.4,1.4L351.3,224.7L351.3,224.7z M351.4,238
					C351.4,237.9,351.4,237.9,351.4,238l2.4-1.4l2.6,1.5l-2.4,1.4L351.4,238L351.4,238z M351.7,229.4
					C351.7,229.4,351.7,229.4,351.7,229.4l2.4-1.4l2.1,1.2l-2.4,1.4L351.7,229.4L351.7,229.4z M353.1,234.5
					C353.1,234.5,353.1,234.5,353.1,234.5l2.4-1.4l2.1,1.2l-2.4,1.4L353.1,234.5L353.1,234.5z M353.8,221.8
					C353.8,221.8,353.8,221.8,353.8,221.8l2.4-1.4l2.1,1.2l-2.4,1.4L353.8,221.8L353.8,221.8z M354.6,226.7
					C354.6,226.7,354.6,226.7,354.6,226.7l2.4-1.4l2.1,1.2l-2.4,1.4L354.6,226.7L354.6,226.7z M355.1,231.3
					C355.1,231.3,355.1,231.3,355.1,231.3l2.4-1.4l2.1,1.2l-2.4,1.4L355.1,231.3L355.1,231.3z M355.2,217.3
					C355.2,217.3,355.2,217.3,355.2,217.3l2.4-1.4l2.1,1.2l-2.4,1.4L355.2,217.3L355.2,217.3z M355.3,240.2
					C355.3,240.2,355.3,240.2,355.3,240.2l2.4-1.4l2.6,1.5l-2.4,1.4L355.3,240.2L355.3,240.2z M356.4,236.5
					C356.4,236.5,356.4,236.5,356.4,236.5l2.4-1.4l2.1,1.2l-2.4,1.4L356.4,236.5L356.4,236.5z M357.2,223.8
					C357.2,223.8,357.2,223.7,357.2,223.8l2.4-1.4l2.1,1.2l-2.4,1.4L357.2,223.8L357.2,223.8z M358,228.6
					C358,228.6,358,228.6,358,228.6l2.4-1.4l2.1,1.2l-2.4,1.4L358,228.6L358,228.6z M358.4,233.3
					C358.4,233.3,358.5,233.2,358.4,233.3l2.4-1.4l2.1,1.2l-2.4,1.4L358.4,233.3L358.4,233.3z M358.5,219.2
					C358.5,219.2,358.6,219.2,358.5,219.2l2.4-1.4l2.1,1.2l-2.4,1.4L358.5,219.2L358.5,219.2z M359.2,242.5
					C359.2,242.4,359.2,242.4,359.2,242.5l2.4-1.4l3.3,1.9l-2.4,1.4L359.2,242.5L359.2,242.5z M359.8,238.4
					C359.8,238.4,359.8,238.4,359.8,238.4l2.4-1.4l6.5,3.7l-2.4,1.4L359.8,238.4L359.8,238.4z M360.6,225.7
					C360.6,225.7,360.6,225.7,360.6,225.7l2.4-1.4l2.1,1.2l-2.4,1.4L360.6,225.7L360.6,225.7z M361.4,230.6
					C361.4,230.6,361.4,230.5,361.4,230.6l2.4-1.4l2.1,1.2l-2.4,1.4L361.4,230.6L361.4,230.6z M361.8,235.2
					C361.8,235.2,361.8,235.2,361.8,235.2l2.4-1.4l2.1,1.2l-2.4,1.4L361.8,235.2L361.8,235.2z M361.9,221.2
					C361.9,221.2,361.9,221.1,361.9,221.2l2.4-1.4l2.1,1.2l-2.4,1.4L361.9,221.2L361.9,221.2z M363.9,227.7
					C363.9,227.6,363.9,227.6,363.9,227.7l2.4-1.4l2.1,1.2l-2.4,1.4L363.9,227.7L363.9,227.7z M364.5,245.5
					C364.5,245.5,364.5,245.5,364.5,245.5l2.4-1.4l2.1,1.2l-2.4,1.4L364.5,245.5L364.5,245.5z M364.7,232.5
					C364.7,232.5,364.7,232.5,364.7,232.5l2.4-1.4l2.1,1.2l-2.4,1.4L364.7,232.5L364.7,232.5z M365.2,237.2
					C365.2,237.1,365.2,237.1,365.2,237.2l2.4-1.4l1.9,1.1c0.1,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5,0,0.6-0.1l3.2-1.8l2.4,1.4l-6.2,3.6
					L365.2,237.2L365.2,237.2z M365.3,223.1C365.3,223.1,365.3,223.1,365.3,223.1l2.4-1.4l2.1,1.2l-2.4,1.4L365.3,223.1L365.3,223.1
					z M367.3,229.6C367.3,229.6,367.3,229.6,367.3,229.6l2.4-1.4l2.1,1.2l-2.4,1.4L367.3,229.6L367.3,229.6z M367.8,247.5
					C367.8,247.4,367.8,247.4,367.8,247.5l2.4-1.4l2.1,1.2l-2.4,1.4L367.8,247.5L367.8,247.5z M368.1,234.4
					C368.1,234.4,368.1,234.4,368.1,234.4l2.4-1.4l2.1,1.2l-2.4,1.4L368.1,234.4L368.1,234.4z M370.1,225.9
					C370.1,225.9,370.1,225.9,370.1,225.9l2.4-1.4l2.1,1.2l-2.4,1.4L370.1,225.9L370.1,225.9z M370.6,231.5
					C370.6,231.5,370.6,231.5,370.6,231.5l2.4-1.4l2.1,1.2l-2.4,1.4L370.6,231.5L370.6,231.5z M371.2,249.4
					C371.2,249.4,371.2,249.4,371.2,249.4l2.4-1.4l2.1,1.2l-2.4,1.4L371.2,249.4L371.2,249.4z M371.6,245.3
					C371.6,245.2,371.6,245.2,371.6,245.3l2.4-1.4l2.1,1.2l-2.4,1.4L371.6,245.3L371.6,245.3z M373.4,227.8
					C373.4,227.8,373.5,227.8,373.4,227.8l2.4-1.4l2.1,1.2l-2.4,1.4L373.4,227.8L373.4,227.8z M374,233.5
					C374,233.5,374,233.5,374,233.5l2.4-1.4l3.6,2.1l-2.4,1.4L374,233.5L374,233.5z M375.3,251.7
					C375.3,251.7,375.3,251.7,375.3,251.7l2.4-1.4l5.4,3.1l-2.4,1.4L375.3,251.7L375.3,251.7z M375.9,238.9
					C375.9,238.9,375.9,238.9,375.9,238.9l2.4-1.4l2.1,1.2l-2.4,1.4L375.9,238.9L375.9,238.9z M376.8,229.8
					C376.8,229.8,376.8,229.7,376.8,229.8l2.4-1.4l2.1,1.2l-2.4,1.4L376.8,229.8L376.8,229.8z M379.1,249.6
					C379.1,249.5,379.1,249.5,379.1,249.6l2.4-1.4l2.1,1.2l-2.4,1.4L379.1,249.6L379.1,249.6z M379.2,240.9
					C379.2,240.9,379.2,240.9,379.2,240.9l2.4-1.4l2.1,1.2l-2.4,1.4L379.2,240.9L379.2,240.9z M379.7,236.7
					C379.7,236.7,379.7,236.7,379.7,236.7l2.4-1.4l2.1,1.2l-2.4,1.4L379.7,236.7L379.7,236.7z M380.2,231.7
					C380.2,231.7,380.2,231.7,380.2,231.7l2.4-1.4l2.1,1.2l-2.4,1.4L380.2,231.7L380.2,231.7z M382,255.6
					C382,255.6,382,255.6,382,255.6l2.4-1.4l2.1,1.2l-2.4,1.4L382,255.6L382,255.6z M382.4,251.5
					C382.4,251.5,382.4,251.5,382.4,251.5l2.4-1.4l2.1,1.2l-2.4,1.4L382.4,251.5L382.4,251.5z M382.6,242.8
					C382.6,242.8,382.6,242.8,382.6,242.8l2.4-1.4l2.1,1.2l-2.4,1.4L382.6,242.8L382.6,242.8z M382.9,247.4
					C382.9,247.3,382.9,247.3,382.9,247.4l2.4-1.4l2.1,1.2l-2.4,1.4L382.9,247.4L382.9,247.4z M383,238.7
					C383,238.7,383,238.7,383,238.7l2.4-1.4l2.1,1.2l-2.4,1.4L383,238.7L383,238.7z M384.3,234.1
					C384.3,234.1,384.3,234.1,384.3,234.1l2.4-1.4l2.1,1.2l-2.4,1.4L384.3,234.1L384.3,234.1z M385.3,257.6
					C385.3,257.5,385.3,257.5,385.3,257.6l6.2-3.6l2.1,1.2l-6.2,3.6L385.3,257.6L385.3,257.6z M385.8,253.4
					C385.8,253.4,385.8,253.4,385.8,253.4l2.4-1.4l2.1,1.2l-2.4,1.4L385.8,253.4L385.8,253.4z M386.2,249.3
					C386.2,249.3,386.2,249.3,386.2,249.3l2.4-1.4l2.1,1.2l-2.4,1.4L386.2,249.3L386.2,249.3z M386.4,240.6
					C386.4,240.6,386.4,240.6,386.4,240.6l2.4-1.4l2.1,1.2l-2.4,1.4L386.4,240.6L386.4,240.6z M386.6,245.2
					C386.6,245.2,386.7,245.1,386.6,245.2l2.4-1.4l2.1,1.2l-2.4,1.4L386.6,245.2L386.6,245.2z M387.6,236
					C387.6,236,387.6,236,387.6,236l2.4-1.4l2.1,1.2l-2.4,1.4L387.6,236L387.6,236z M389.6,251.2
					C389.6,251.2,389.6,251.2,389.6,251.2l2.4-1.4l2.1,1.2l-2.4,1.4L389.6,251.2L389.6,251.2z M390,247.1
					C390,247.1,390,247.1,390,247.1l2.4-1.4l2.1,1.2l-2.4,1.4L390,247.1L390,247.1z M390.4,243C390.4,243,390.5,242.9,390.4,243
					l2.4-1.4l2.1,1.2l-2.4,1.4L390.4,243L390.4,243z M391,238C391,238,391,237.9,391,238l2.4-1.4l2.1,1.2l-2.4,1.4L391,238L391,238z
					 M392.9,253.2C392.9,253.2,392.9,253.2,392.9,253.2l6.2-3.6l2.1,1.2l-6.2,3.6L392.9,253.2L392.9,253.2z M393.4,249
					C393.4,249,393.4,249,393.4,249l2.4-1.4l2.1,1.2l-2.4,1.4L393.4,249L393.4,249z M393.8,244.9
					C393.8,244.9,393.8,244.9,393.8,244.9l2.4-1.4l2.1,1.2l-2.4,1.4L393.8,244.9L393.8,244.9z M396,239.8
					C396,239.7,396,239.7,396,239.8l1-0.6l2.1,1.2l-1.1,0.7L396,239.8L396,239.8z M397.2,246.9C397.2,246.8,397.2,246.8,397.2,246.9
					l2.4-1.4l2.1,1.2l-2.4,1.4L397.2,246.9L397.2,246.9z M399.4,241.7C399.4,241.7,399.4,241.7,399.4,241.7l1-0.6l2.1,1.2l-1.1,0.7
					L399.4,241.7L399.4,241.7z M400.5,248.8C400.5,248.8,400.5,248.8,400.5,248.8l2.4-1.4l2.1,1.2l-2.4,1.4L400.5,248.8L400.5,248.8
					z M402.8,243.6C402.8,243.6,402.8,243.6,402.8,243.6l1-0.6l2.1,1.2l-1.1,0.7L402.8,243.6L402.8,243.6z M406.1,245.6
					C406.1,245.6,406.1,245.5,406.1,245.6l1-0.6l2.1,1.2l-1.1,0.7L406.1,245.6L406.1,245.6z"
                        />
                      </g>
                    </g>
                    <g id="Device">
                      <polygon
                        id="Shadow_29_"
                        class="st8"
                        points="475.4,253.5 498.3,240.2 383.6,174 360.6,187.2 			"
                      />
                      <g id="Monitor_2_">
                        <g id="XMLID_3761_">
                          <g id="XMLID_3766_">
                            <path
                              id="XMLID_3767_"
                              class="st10"
                              d="M410.4,231.9l-16.4-9.5c-1.7-1-2.5-2.3-2.5-3.5c0-1.3,0-2.3,0-2.3l15.4-8.8
							c0.9-0.5,1.6-1.8,1.6-2.8v-20.2l28.6,16.5l0,20.2c0,1-0.7,2.3-1.6,2.8l-12.9,7.5C419.3,233.9,413.8,233.9,410.4,231.9z"
                            />
                          </g>
                          <path
                            id="XMLID_3765_"
                            class="st11"
                            d="M430.3,225.2v2.3l5.2-3c0.9-0.5,1.6-1.8,1.6-2.8v-20.2l-5.5-3.1
						c-0.1,1.4-0.2,2.8-0.3,4.2l3.8,2.2v15.8c0,1-0.7,2.3-1.6,2.8L430.3,225.2z"
                          />
                          <path
                            id="XMLID_3764_"
                            class="st12"
                            d="M435.2,220.4c0,1-0.7,2.3-1.6,2.8l-10.9,6.4c-3.4,2-8.9,2-12.2,0l-16.4-9.5
						c-3.4-1.9-3.4-5.1,0-7.1l10.9-6.4c0.9-0.5,1.6-1.8,1.6-2.8l0-15.8l28.6,16.5V220.4z"
                          />
                          <path
                            id="XMLID_3763_"
                            class="st13"
                            d="M401.6,208.6L401.6,208.6l3.3-1.9c0.9-0.5,1.6-1.8,1.6-2.8v-15.8l28.6,16.5v15.8
						c0,1-0.7,2.3-1.6,2.8l-3.3,1.9L401.6,208.6z"
                          />
                          <path
                            id="XMLID_3762_"
                            class="st13"
                            d="M391.5,216.6c0,0,0,1,0,2.3c0,1.3,0.8,2.6,2.5,3.5l16.4,9.5c1.6,0.9,3.7,1.4,5.8,1.5
						v-2.3c-2.1,0-4.2-0.5-5.8-1.5l-16.4-9.5C392.3,219.2,391.5,217.9,391.5,216.6z"
                          />
                        </g>
                        <path
                          id="XMLID_3760_"
                          class="st3"
                          d="M481.1,242.2c-1.1,0.6-2.8,0.6-4-0.1c-14.1-8.1-96.6-55.8-110.7-63.9
					c-1.1-0.7-2-2.2-2-3.5V77.8c0-1.3,0.9-2.8,1.9-3.5c1.1-0.6,2.8-0.6,4,0.1L481,138.4c1.1,0.7,2,2.2,2,3.5l0,96.9
					C483,240.1,482.2,241.6,481.1,242.2z"
                        />
                        <path
                          id="XMLID_3758_"
                          class="st2"
                          d="M477.2,242.2l-110.7-63.9c-1.1-0.7-2-2.2-2-3.5V77.8c0-1.3,0.9-1.8,2-1.2l110.7,63.9
					c1.1,0.7,2,2.2,2,3.5l0,96.9C479.2,242.3,478.3,242.8,477.2,242.2z"
                        />
                        <path
                          id="XMLID_3757_"
                          class="st11"
                          d="M477.5,242.6c1.1,0.5,2.7,0.5,3.7-0.1c1.1-0.6,1.9-2.2,1.9-3.5v-10.9l-3.8,2.2v10.9
					C479.2,242.4,478.5,243,477.5,242.6z"
                        />
                        <path
                          id="XMLID_3756_"
                          class="st14"
                          d="M479.2,230.3l0,10.9c0,1.3-0.9,1.8-2,1.2l-110.7-63.9c-1.1-0.7-2-2.2-2-3.5v-10.9
					L479.2,230.3z"
                        />
                        <path
                          id="XMLID_3755_"
                          class="st4"
                          d="M475.4,222.1l0-76.7c0-0.8-0.5-1.7-1.2-2.1L369.5,82.8c-0.7-0.4-1.2-0.1-1.2,0.7l0,76.7
					c0,0.8,0.5,1.7,1.2,2.1l104.7,60.4C474.9,223.1,475.4,222.8,475.4,222.1z"
                        />
                        <path
                          id="XMLID_3754_"
                          class="st15"
                          d="M475.4,222.1l0-76.7c0-0.8-0.5-1.7-1.2-2.1L369.5,82.8c-0.7-0.4-1.2-0.1-1.2,0.7l0,76.7
					c0,0.8,0.5,1.7,1.2,2.1l104.7,60.4C474.9,223.1,475.4,222.8,475.4,222.1z"
                        />
                        <path
                          id="XMLID_3658_"
                          class="st3"
                          d="M421.8,201.4c1.1,0.6,1.9,2.1,1.9,3.3c0,1.2-0.9,1.7-1.9,1.1c-1.1-0.6-1.9-2.1-1.9-3.3
					C419.9,201.3,420.8,200.8,421.8,201.4z"
                        />
                        <path
                          id="XMLID_3657_"
                          class="st9"
                          d="M478.6,142.1c0.4,0.6,0.6,1.4,0.6,2v86.2l3.8-2.2v-86.2c0-0.6-0.2-1.4-0.6-2L478.6,142.1z
					"
                        />
                      </g>
                    </g>
                    <g id="Mouse_2_">
                      <path
                        class="st8"
                        d="M425.9,270.2l9.4,5.4c3,1.7,7.6,1.5,10.4-0.5l15.5-11.4c2.8-2,2.6-5-0.4-6.8l-4.7-2.7
				c-3-1.7-7.9-1.9-10.9-0.3l-19.2,10.4C423,265.9,423,268.5,425.9,270.2z"
                      />
                      <path
                        class="st2"
                        d="M461.6,258.8l-10.8,8.4l-7.9,6.2c-0.8,0.4-1.7,0.6-2.5,0.6c-1.3,0.1-2.7-0.2-3.8-0.8l-8.4-4.9c0,0,0,0,0,0
				c0,0,0,0,0,0c-3-1.7-3.7-5.7-1.7-8.5c0.1-0.1,0.2-0.2,0.2-0.3c1.6-2.2,4-3.7,6.8-4.3l4.3-0.9c6.9-1.4,12.6-2.1,13.3-1.6
				L461.6,258.8C461.5,258.8,461.5,258.8,461.6,258.8z"
                      />
                      <path
                        class="st4"
                        d="M455.5,263.5l0.6-0.5l-9.5-5.5l8.3-2.6c-0.3-0.2-0.6-0.3-0.8-0.5l-8.3,2.6l-5.7-3.3
				c-0.4,0.1-0.7,0.1-1.1,0.2L455.5,263.5z"
                      />
                      <path
                        class="st9"
                        d="M443.8,272.9C443.8,272.9,443.8,272.9,443.8,272.9C443.8,272.9,443.9,272.9,443.8,272.9l7.6-5.5l10.2-7.4
				v-1.2c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.8-0.3-5.2,1.1-10.5,3.2l-3.5,1.4c-1.1,0.4-2.1,1-3,1.8c-2.5,2.1-4.1,5.3-4.1,8.7v0.2
				c0.9,0,1.8-0.2,2.5-0.6L443.8,272.9z"
                      />
                    </g>
                  </g>
                </g>
                <g id="Window">
                  <g id="Layer_10">
                    <g id="Window_3_">
                      <path
                        class="st3"
                        d="M382.6,57.8c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0.1-0.1
				c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.4-0.2,0.8-0.1,1.3,0.2l129.2,74.5
				c1.1,0.6,2,2.1,2,3.4v93.5c0,0,0,0,0,0v0.4c0,0.5-0.3,1-0.7,1.2c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0.1
				c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.2l-0.1,0c0,0,0,0,0,0l-1.9-1.1l-127.3-73.5c-1.1-0.6-2-2.1-2-3.4v-94
				C382.6,58,382.6,57.9,382.6,57.8z"
                      />
                      <g id="XMLID_3010_">
                        <g id="XMLID_3044_">
                          <path
                            class="st4"
                            d="M383.2,56.8c0.4-0.2,0.8-0.1,1.3,0.2l129.2,74.5c1.1,0.6,2,2.1,2,3.4v6.4l2.5-1.5v-6.3
						c0-1.2-0.9-2.8-1.9-3.4L387,55.5c-0.6-0.3-1.1-0.3-1.4-0.1C385.2,55.6,383.6,56.6,383.2,56.8z"
                          />
                        </g>
                        <path
                          id="XMLID_3036_"
                          class="st4"
                          d="M382.6,58.1c0-1.2,0.9-1.7,1.9-1.1l129.2,74.5c1.1,0.6,2,2.1,2,3.4l0,6.4L382.6,64.5V58.1
					z"
                        />
                        <g id="XMLID_3027_" class="st6">
                          <path
                            class="st14"
                            d="M384.5,57l129.2,74.5c0.5,0.3,1,0.8,1.4,1.4l2.5-1.4c-0.3-0.6-0.8-1.1-1.4-1.4L387,55.5
						c-0.6-0.3-1.1-0.3-1.4-0.1c-0.3,0.2-2,1.1-2.4,1.4C383.5,56.6,384,56.7,384.5,57z"
                          />
                        </g>
                        <path
                          id="XMLID_3011_"
                          class="st8"
                          d="M518.1,139.8l0-6.3c0-0.6-0.2-1.3-0.6-2l-2.5,1.4c0.4,0.6,0.6,1.3,0.6,1.9v6.4
					L518.1,139.8z"
                        />
                      </g>
                      <g id="XMLID_3000_">
                        <path
                          id="XMLID_3009_"
                          class="st3"
                          d="M511.2,132.9c0.6,0.4,1.1,1.4,1.1,2.3s-0.5,1.4-1.1,1.1c-0.6-0.4-1.1-1.4-1.1-2.3
					C510.1,133,510.6,132.5,511.2,132.9z"
                        />
                        <path
                          id="XMLID_3002_"
                          class="st3"
                          d="M507.3,130.6c0.6,0.4,1.1,1.4,1.1,2.3c0,0.9-0.5,1.4-1.1,1.1c-0.6-0.4-1.1-1.4-1.1-2.3
					C506.2,130.7,506.7,130.2,507.3,130.6z"
                        />
                        <path
                          id="XMLID_3001_"
                          class="st3"
                          d="M503.3,128.3c0.6,0.4,1.1,1.4,1.1,2.3c0,0.9-0.5,1.4-1.1,1.1c-0.6-0.4-1.1-1.4-1.1-2.3
					S502.7,127.9,503.3,128.3z"
                        />
                      </g>
                      <path
                        class="st16"
                        d="M514.9,230l0.1-0.1l2.1-1.2c0.6-0.3,0.9-0.9,1-1.5c0-0.1,0-0.2,0-0.3v-87.2l-2.5,1.5v87.6
				C515.6,229.3,515.4,229.7,514.9,230C515,229.9,515,230,514.9,230C515,230,515,230,514.9,230L514.9,230z"
                      />
                    </g>
                  </g>
                  <g id="Layer_9">
                    <g id="Code">
                      <g>
                        <path
                          id="XMLID_2979_"
                          class="st4"
                          d="M422.1,97.7l27.2,15.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-27.2-15.7
					c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,97.7,421.7,97.5,422.1,97.7z"
                        />
                        <path
                          id="XMLID_2977_"
                          class="st10"
                          d="M432.5,127.8l10.3,5.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-10.3-5.9
					c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,127.7,432.1,127.6,432.5,127.8z"
                        />
                        <path
                          id="XMLID_2971_"
                          class="st10"
                          d="M432.5,133.8l10.3,5.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-10.3-5.9
					c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,133.7,432.1,133.6,432.5,133.8z"
                        />
                        <path
                          id="XMLID_2967_"
                          class="st4"
                          d="M452,115l54.5,31.4c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4L452,116.5
					c-0.4-0.2-0.7-0.7-0.7-1.2C451.4,114.9,451.7,114.8,452,115z"
                        />
                        <path
                          id="XMLID_2926_"
                          class="st4"
                          d="M432.8,169.9l16.6,9.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-16.6-9.6
					c-0.4-0.2-0.7-0.7-0.7-1.2C432.1,169.9,432.4,169.7,432.8,169.9z"
                        />
                        <path
                          id="XMLID_2925_"
                          class="st4"
                          d="M452,181.1l54.5,31.4c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4L452,182.6
					c-0.4-0.2-0.7-0.7-0.7-1.2C451.4,181,451.7,180.9,452,181.1z"
                        />
                        <g id="XMLID_2876_">
                          <path
                            id="XMLID_2905_"
                            class="st4"
                            d="M422.1,103.8l13.7,7.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-13.7-7.9
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,103.7,421.7,103.5,422.1,103.8z"
                          />
                          <path
                            id="XMLID_2890_"
                            class="st17"
                            d="M422.1,103.8l13.7,7.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-13.7-7.9
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,103.7,421.7,103.5,422.1,103.8z"
                          />
                        </g>
                        <g id="XMLID_2873_">
                          <path
                            id="XMLID_2875_"
                            class="st4"
                            d="M422.1,109.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,109.7,421.7,109.5,422.1,109.8z"
                          />
                          <path
                            id="XMLID_2874_"
                            class="st17"
                            d="M422.1,109.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,109.7,421.7,109.5,422.1,109.8z"
                          />
                        </g>
                        <g id="XMLID_2867_">
                          <path
                            id="XMLID_2869_"
                            class="st4"
                            d="M422.1,121.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,121.7,421.7,121.6,422.1,121.8z"
                          />
                          <path
                            id="XMLID_2868_"
                            class="st17"
                            d="M422.1,121.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,121.7,421.7,121.6,422.1,121.8z"
                          />
                        </g>
                        <g id="XMLID_2864_">
                          <path
                            id="XMLID_2866_"
                            class="st4"
                            d="M422.1,127.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,127.7,421.7,127.6,422.1,127.8z"
                          />
                          <path
                            id="XMLID_2865_"
                            class="st17"
                            d="M422.1,127.8l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,127.7,421.7,127.6,422.1,127.8z"
                          />
                        </g>
                        <g id="XMLID_2855_">
                          <path
                            id="XMLID_2863_"
                            class="st4"
                            d="M438.5,113.2l8.2,4.8c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.2-4.8
						c-0.4-0.2-0.7-0.7-0.7-1.2C437.8,113.1,438.1,113,438.5,113.2z"
                          />
                          <path
                            id="XMLID_2862_"
                            class="st17"
                            d="M438.5,113.2l8.2,4.8c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.2-4.8
						c-0.4-0.2-0.7-0.7-0.7-1.2C437.8,113.1,438.1,113,438.5,113.2z"
                          />
                        </g>
                        <g id="XMLID_2853_">
                          <path
                            id="XMLID_2854_"
                            class="st14"
                            d="M451.3,156.6l55.3,31.8c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-55.3-31.8
						c-0.4-0.2-0.7-0.7-0.7-1.2C450.6,156.6,450.9,156.4,451.3,156.6z"
                          />
                        </g>
                        <g id="XMLID_2851_">
                          <path
                            id="XMLID_2852_"
                            class="st14"
                            d="M498.3,177.7l8.2,4.8c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.2-4.8
						c-0.4-0.2-0.7-0.7-0.7-1.2C497.7,177.6,498,177.5,498.3,177.7z"
                          />
                        </g>
                        <g id="XMLID_2849_">
                          <path
                            id="XMLID_2850_"
                            class="st14"
                            d="M440.3,144.3l55.3,31.8c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-55.3-31.8
						c-0.4-0.2-0.7-0.7-0.7-1.2C439.6,144.2,440,144.1,440.3,144.3z"
                          />
                        </g>
                        <g id="XMLID_2846_">
                          <path
                            id="XMLID_2848_"
                            class="st4"
                            d="M432.5,115.8l13.7,7.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-13.7-7.9
						c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,115.7,432.1,115.6,432.5,115.8z"
                          />
                          <path
                            id="XMLID_2847_"
                            class="st17"
                            d="M432.5,115.8l13.7,7.9c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-13.7-7.9
						c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,115.7,432.1,115.6,432.5,115.8z"
                          />
                        </g>
                        <g id="XMLID_2823_">
                          <path
                            id="XMLID_2845_"
                            class="st4"
                            d="M448.9,125.2l57.2,33.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-57.2-33.1
						c-0.4-0.2-0.7-0.7-0.7-1.2C448.2,125.1,448.6,125,448.9,125.2z"
                          />
                          <path
                            id="XMLID_2839_"
                            class="st17"
                            d="M448.9,125.2l57.2,33.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-57.2-33.1
						c-0.4-0.2-0.7-0.7-0.7-1.2C448.2,125.1,448.6,125,448.9,125.2z"
                          />
                        </g>
                        <path
                          id="XMLID_2978_"
                          class="st10"
                          d="M432.5,151.9l74,42.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-74-42.7
					c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,151.9,432.1,151.7,432.5,151.9z"
                        />
                        <g id="XMLID_2870_">
                          <path
                            id="XMLID_2872_"
                            class="st4"
                            d="M422.1,145.9l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,145.9,421.7,145.7,422.1,145.9z"
                          />
                          <path
                            id="XMLID_2871_"
                            class="st17"
                            d="M422.1,145.9l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,145.9,421.7,145.7,422.1,145.9z"
                          />
                        </g>
                        <path
                          id="XMLID_2986_"
                          class="st10"
                          d="M432.5,158l74,42.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-74-42.7
					c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,157.9,432.1,157.7,432.5,158z"
                        />
                        <g id="XMLID_2981_">
                          <path
                            id="XMLID_2984_"
                            class="st4"
                            d="M422.1,151.9l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,151.9,421.7,151.7,422.1,151.9z"
                          />
                          <path
                            id="XMLID_2983_"
                            class="st17"
                            d="M422.1,151.9l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,151.9,421.7,151.7,422.1,151.9z"
                          />
                        </g>
                        <path
                          id="XMLID_2994_"
                          class="st10"
                          d="M432.5,164l74,42.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-74-42.7
					c-0.4-0.2-0.7-0.7-0.7-1.2C431.8,163.9,432.1,163.7,432.5,164z"
                        />
                        <g id="XMLID_2991_">
                          <path
                            id="XMLID_2993_"
                            class="st4"
                            d="M422.1,158l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,157.9,421.7,157.7,422.1,158z"
                          />
                          <path
                            id="XMLID_2992_"
                            class="st17"
                            d="M422.1,158l6.4,3.7c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-6.4-3.7
						c-0.4-0.2-0.7-0.7-0.7-1.2C421.4,157.9,421.7,157.7,422.1,158z"
                          />
                        </g>
                      </g>
                      <g class="st5">
                        <path
                          class="st14"
                          d="M412.1,91.7l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,91.7,411.7,91.5,412.1,91.7z"
                        />
                        <path
                          class="st14"
                          d="M412.1,97.8l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,97.7,411.7,97.6,412.1,97.8z"
                        />
                        <path
                          class="st14"
                          d="M412.1,103.9l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,103.8,411.7,103.6,412.1,103.9z"
                        />
                        <path
                          class="st14"
                          d="M412.1,109.9l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,109.9,411.7,109.7,412.1,109.9z"
                        />
                        <path
                          class="st14"
                          d="M412.1,116l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2c-0.4-0.2-0.7-0.7-0.7-1.2
					l0-1.4C411.4,115.9,411.7,115.8,412.1,116z"
                        />
                        <path
                          class="st14"
                          d="M412.1,122l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2c-0.4-0.2-0.7-0.7-0.7-1.2
					l0-1.4C411.4,122,411.7,121.8,412.1,122z"
                        />
                        <path
                          class="st14"
                          d="M412.1,128.1l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.8-0.7-1.2l0-1.4C411.4,128.1,411.7,127.9,412.1,128.1z"
                        />
                        <path
                          class="st14"
                          d="M412.1,134.2l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,134.1,411.7,134,412.1,134.2z"
                        />
                        <path
                          class="st14"
                          d="M412.1,140.2l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,140.2,411.7,140,412.1,140.2z"
                        />
                        <path
                          class="st14"
                          d="M412.1,146.3l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,146.3,411.7,146.1,412.1,146.3z"
                        />
                        <path
                          class="st14"
                          d="M412.1,152.4l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,152.3,411.7,152.2,412.1,152.4z"
                        />
                        <path
                          class="st14"
                          d="M412.1,158.4l3.8,2.2c0.4,0.2,0.7,0.7,0.7,1.2v1.4c0,0.4-0.3,0.6-0.7,0.4l-3.8-2.2
					c-0.4-0.2-0.7-0.7-0.7-1.2l0-1.4C411.4,158.4,411.7,158.2,412.1,158.4z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M387.1,72l20.4,11.8c0.8,0.4,1.4,1.5,1.4,2.4l0,23.6c0,0.9-0.6,1.2-1.4,0.8l-20.4-11.8
					c-0.8-0.4-1.4-1.5-1.4-2.4V72.8C385.7,71.9,386.3,71.6,387.1,72z"
                        />
                        <g>
                          <g>
                            <path
                              id="XMLID_2822_"
                              class="st4"
                              d="M389,95.4l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,95.3,388.6,95.1,389,95.4z"
                            />
                            <path
                              id="XMLID_2821_"
                              class="st17"
                              d="M389,95.4l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,95.3,388.6,95.1,389,95.4z"
                            />
                          </g>
                          <g>
                            <path
                              id="XMLID_2820_"
                              class="st4"
                              d="M389,76.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,76,388.6,75.9,389,76.1z"
                            />
                            <path
                              id="XMLID_529_"
                              class="st17"
                              d="M389,76.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,76,388.6,75.9,389,76.1z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,81.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,81.4,391.2,81.2,391.6,81.5z"
                            />
                            <path
                              class="st17"
                              d="M391.6,81.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,81.4,391.2,81.2,391.6,81.5z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,85.3l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,85.2,391.2,85.1,391.6,85.3z"
                            />
                            <path
                              class="st17"
                              d="M391.6,85.3l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,85.2,391.2,85.1,391.6,85.3z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M389,87.7l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,87.6,388.6,87.4,389,87.7z"
                            />
                            <path
                              class="st17"
                              d="M389,87.7l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,87.6,388.6,87.4,389,87.7z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,93l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,93,391.2,92.8,391.6,93z"
                            />
                            <path
                              class="st17"
                              d="M391.6,93l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,93,391.2,92.8,391.6,93z"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              id="XMLID_354_"
                              class="st4"
                              d="M394.1,79.1l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,79,393.7,78.8,394.1,79.1z"
                            />
                            <path
                              id="XMLID_353_"
                              class="st18"
                              d="M394.1,79.1l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,79,393.7,78.8,394.1,79.1z"
                            />
                          </g>
                          <g>
                            <path
                              id="XMLID_352_"
                              class="st4"
                              d="M394.1,98.3l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,98.3,393.7,98.1,394.1,98.3z"
                            />
                            <path
                              id="XMLID_343_"
                              class="st18"
                              d="M394.1,98.3l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,98.3,393.7,98.1,394.1,98.3z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,84.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,84.4,396.4,84.2,396.8,84.4z"
                            />
                            <path
                              class="st18"
                              d="M396.8,84.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,84.4,396.4,84.2,396.8,84.4z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,88.3l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,88.2,396.4,88.1,396.8,88.3z"
                            />
                            <path
                              class="st18"
                              d="M396.8,88.3l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,88.2,396.4,88.1,396.8,88.3z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M394.1,90.6l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6c-0.4-0.2-0.7-0.7-0.7-1.2
							C393.4,90.6,393.7,90.4,394.1,90.6z"
                            />
                            <path
                              class="st18"
                              d="M394.1,90.6l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,90.6,393.7,90.4,394.1,90.6z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,96l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,95.9,396.4,95.8,396.8,96z"
                            />
                            <path
                              class="st18"
                              d="M396.8,96l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,95.9,396.4,95.8,396.8,96z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M387.1,103.2l20.4,11.8c0.8,0.4,1.4,1.5,1.4,2.4l0,46.5c0,0.9-0.6,1.2-1.4,0.8l-20.4-11.8
					c-0.8-0.4-1.4-1.5-1.4-2.4V104C385.7,103.1,386.3,102.7,387.1,103.2z"
                        />
                        <g>
                          <g>
                            <path
                              id="XMLID_342_"
                              class="st4"
                              d="M389,107.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,107.2,388.6,107,389,107.2z"
                            />
                            <path
                              id="XMLID_341_"
                              class="st17"
                              d="M389,107.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1
							c-0.4-0.2-0.7-0.7-0.7-1.2C388.3,107.2,388.6,107,389,107.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,112.6l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,112.5,391.2,112.4,391.6,112.6z"
                            />
                            <path
                              class="st17"
                              d="M391.6,112.6l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,112.5,391.2,112.4,391.6,112.6z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,116.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,116.4,391.2,116.2,391.6,116.5z"
                            />
                            <path
                              class="st17"
                              d="M391.6,116.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,116.4,391.2,116.2,391.6,116.5z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M389,118.8l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,118.7,388.6,118.6,389,118.8z"
                            />
                            <path
                              class="st17"
                              d="M389,118.8l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,118.7,388.6,118.6,389,118.8z"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              id="XMLID_340_"
                              class="st4"
                              d="M394.1,110.2l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,110.1,393.7,110,394.1,110.2z"
                            />
                            <path
                              id="XMLID_321_"
                              class="st18"
                              d="M394.1,110.2l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,110.1,393.7,110,394.1,110.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,115.6l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,115.5,396.4,115.4,396.8,115.6z"
                            />
                            <path
                              class="st18"
                              d="M396.8,115.6l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,115.5,396.4,115.4,396.8,115.6z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,119.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,119.4,396.4,119.2,396.8,119.4z"
                            />
                            <path
                              class="st18"
                              d="M396.8,119.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,119.4,396.4,119.2,396.8,119.4z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M394.1,121.8l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,121.7,393.7,121.6,394.1,121.8z"
                            />
                            <path
                              class="st18"
                              d="M394.1,121.8l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,121.7,393.7,121.6,394.1,121.8z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,124.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,124.1,391.2,124,391.6,124.2z"
                            />
                            <path
                              class="st17"
                              d="M391.6,124.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,124.1,391.2,124,391.6,124.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,128.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,128,391.2,127.8,391.6,128.1z"
                            />
                            <path
                              class="st17"
                              d="M391.6,128.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,128,391.2,127.8,391.6,128.1z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,127.2l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,127.1,396.4,127,396.8,127.2z"
                            />
                            <path
                              class="st18"
                              d="M396.8,127.2l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,127.1,396.4,127,396.8,127.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,131l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,131,396.4,130.8,396.8,131z"
                            />
                            <path
                              class="st18"
                              d="M396.8,131l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,131,396.4,130.8,396.8,131z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M389,138.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,138.1,388.6,137.9,389,138.1z"
                            />
                            <path
                              class="st17"
                              d="M389,138.1l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,138.1,388.6,137.9,389,138.1z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M394.1,141.1l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,141,393.7,140.9,394.1,141.1z"
                            />
                            <path
                              class="st18"
                              d="M394.1,141.1l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,141,393.7,140.9,394.1,141.1z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,143.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,143.5,391.2,143.3,391.6,143.5z"
                            />
                            <path
                              class="st17"
                              d="M391.6,143.5l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,143.5,391.2,143.3,391.6,143.5z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,147.4l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,147.3,391.2,147.2,391.6,147.4z"
                            />
                            <path
                              class="st17"
                              d="M391.6,147.4l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,147.3,391.2,147.2,391.6,147.4z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,146.5l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,146.4,396.4,146.3,396.8,146.5z"
                            />
                            <path
                              class="st18"
                              d="M396.8,146.5l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2s-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,146.4,396.4,146.3,396.8,146.5z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,150.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,150.3,396.4,150.1,396.8,150.4z"
                            />
                            <path
                              class="st18"
                              d="M396.8,150.4l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,150.3,396.4,150.1,396.8,150.4z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M391.6,131.9l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,131.8,391.2,131.7,391.6,131.9z"
                            />
                            <path
                              class="st17"
                              d="M391.6,131.9l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C390.9,131.8,391.2,131.7,391.6,131.9z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M389,134.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,134.2,388.6,134,389,134.2z"
                            />
                            <path
                              class="st17"
                              d="M389,134.2l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,134.2,388.6,134,389,134.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M396.8,134.9l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,134.8,396.4,134.6,396.8,134.9z"
                            />
                            <path
                              class="st18"
                              d="M396.8,134.9l8.8,5.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-8.8-5.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C396.1,134.8,396.4,134.6,396.8,134.9z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M394.1,137.2l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,137.1,393.7,137,394.1,137.2z"
                            />
                            <path
                              class="st18"
                              d="M394.1,137.2l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,137.1,393.7,137,394.1,137.2z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M389,149.8l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,149.7,388.6,149.6,389,149.8z"
                            />
                            <path
                              class="st17"
                              d="M389,149.8l1.9,1.1c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-1.9-1.1c-0.4-0.2-0.7-0.7-0.7-1.2
							C388.3,149.7,388.6,149.6,389,149.8z"
                            />
                          </g>
                          <g>
                            <path
                              class="st4"
                              d="M394.1,152.8l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,152.7,393.7,152.5,394.1,152.8z"
                            />
                            <path
                              class="st18"
                              d="M394.1,152.8l11.5,6.6c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.3,0.6-0.7,0.4l-11.5-6.6
							c-0.4-0.2-0.7-0.7-0.7-1.2C393.4,152.7,393.7,152.5,394.1,152.8z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Window">
                  <g id="Window_1">
                    <g id="XMLID_3556_">
                      <g id="XMLID_3571_" class="st19">
                        <path
                          class="st3"
                          d="M500.6,168.6V245c0,1,0.7,2.2,1.6,2.7l43.9,25.3c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0,0.1,0,0.2,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.5,0.2-0.8v-76
					c0-1-0.7-2.2-1.6-2.7l-43.9-25.3c-0.4-0.2-0.8-0.3-1.1-0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1
					c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1C500.6,168.5,500.6,168.5,500.6,168.6z"
                        />
                      </g>
                      <g id="XMLID_3563_">
                        <g id="XMLID_3568_">
                          <path
                            class="st4"
                            d="M501.1,167.6c0.3-0.1,0.7-0.1,1.1,0.1l43.9,25.3c0.9,0.5,1.6,1.7,1.6,2.7v5.2l2.5-1.5v-5.1
						c0-1-0.7-2.2-1.6-2.7l-43.9-25.3c-0.5-0.3-0.9-0.3-1.2-0.1C503.3,166.3,501.5,167.4,501.1,167.6z"
                          />
                        </g>
                        <path
                          id="XMLID_3567_"
                          class="st4"
                          d="M500.6,168.6c0-1,0.7-1.4,1.6-0.9l43.9,25.3c0.9,0.5,1.6,1.7,1.6,2.7l0,5.2l-47-27.1
					V168.6z"
                        />
                        <g id="XMLID_3566_" class="st6">
                          <path
                            class="st14"
                            d="M502.2,167.7l43.9,25.3c0.4,0.3,0.8,0.7,1.1,1.2l2.5-1.4c-0.3-0.5-0.7-0.9-1.1-1.2l-43.9-25.3
						c-0.5-0.3-0.9-0.3-1.2-0.1c-0.3,0.2-2.1,1.2-2.4,1.4C501.4,167.4,501.8,167.5,502.2,167.7z"
                          />
                        </g>
                        <path
                          id="XMLID_3564_"
                          class="st8"
                          d="M550.2,199.5l0-5.2c0-0.5-0.2-1.1-0.5-1.6l-2.5,1.4c0.3,0.5,0.5,1.1,0.5,1.6v5.2
					L550.2,199.5z"
                        />
                      </g>
                      <g id="XMLID_3559_">
                        <path
                          id="XMLID_3562_"
                          class="st3"
                          d="M545.3,195.1c0.5,0.3,0.9,1.1,0.9,1.9c0,0.8-0.4,1.1-0.9,0.9c-0.5-0.3-0.9-1.1-0.9-1.9
					C544.4,195.2,544.9,194.8,545.3,195.1z"
                        />
                        <path
                          id="XMLID_3561_"
                          class="st3"
                          d="M542.1,193.2c0.5,0.3,0.9,1.1,0.9,1.9c0,0.8-0.4,1.1-0.9,0.9c-0.5-0.3-0.9-1.1-0.9-1.9
					C541.2,193.3,541.6,193,542.1,193.2z"
                        />
                        <path
                          id="XMLID_3560_"
                          class="st3"
                          d="M538.9,191.4c0.5,0.3,0.9,1.1,0.9,1.9c0,0.8-0.4,1.1-0.9,0.9c-0.5-0.3-0.9-1.1-0.9-1.9
					C538,191.5,538.4,191.1,538.9,191.4z"
                        />
                      </g>
                      <g id="XMLID_3558_" class="st20">
                        <path
                          class="st2"
                          d="M547.1,273.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.2,0.5-0.3l0,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0
					c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.8-0.4,0.9-0.5c0.1-0.1,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3
					c0.1-0.2,0.2-0.5,0.2-0.8v-70.8l-2.5,1.5v70.8c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.2,0.3-0.3,0.4C547.2,273,547.1,273,547.1,273.1
					C547.1,273,547.1,273,547.1,273.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Layer_12">
                    <g id="XMLID_2916_">
                      <g id="XMLID_3493_">
                        <path
                          id="XMLID_3552_"
                          class="st4"
                          d="M504.2,182.6c0-0.1,0-0.2,0.1-0.2l1.1-1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
					c0,0.1,0,0.1,0,0.2v0.8c0,0.1,0,0.2,0,0.2c0,0,0,0.1-0.1,0.1l-0.6,0.6l0.6,1.2c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.2v0.8
					c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1-0.1l-1.1-2.4c-0.1-0.1-0.1-0.2-0.1-0.3V182.6z"
                        />
                        <path
                          id="XMLID_3548_"
                          class="st4"
                          d="M508.4,181.5c0-0.1,0-0.1,0.1-0.1c0,0,0.1,0,0.2,0l0.4,0.2c0,0,0.1,0.1,0.1,0.1
					c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1l-2,6.1c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0-0.2,0l-0.4-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2
					c0,0,0,0,0-0.1L508.4,181.5z"
                        />
                        <path
                          id="XMLID_3541_"
                          class="st4"
                          d="M511.4,187.3c0,0.1,0,0.2-0.1,0.2l-1.1,1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
					c0-0.1,0-0.1,0-0.2v-0.8c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0.1-0.1l0.6-0.6l-0.6-1.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2v-0.8
					c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0.1l1.1,2.4c0.1,0.1,0.1,0.2,0.1,0.3V187.3z"
                        />
                        <path
                          id="XMLID_3522_"
                          class="st4"
                          d="M514.6,190.4c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v0.9c0,0.1,0,0.1,0,0.1
					c0,0-0.1,0-0.1,0l-0.5-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.9c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0L514.6,190.4z"
                        />
                        <path
                          id="XMLID_3499_"
                          class="st4"
                          d="M516.4,191.5c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v0.9c0,0.1,0,0.1,0,0.1
					c0,0-0.1,0-0.1,0l-0.5-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.9c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0L516.4,191.5z"
                        />
                        <path
                          id="XMLID_3497_"
                          class="st4"
                          d="M518.2,192.5c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2v0.9c0,0.1,0,0.1,0,0.1
					c0,0-0.1,0-0.1,0l-0.5-0.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.9c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0L518.2,192.5z"
                        />
                      </g>
                      <path
                        id="XMLID_3489_"
                        class="st4"
                        d="M504.7,189.5l26.4,15.2c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-26.4-15.2
				c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,189.4,504.4,189.3,504.7,189.5z"
                      />
                      <path
                        id="XMLID_3488_"
                        class="st10"
                        d="M513.2,209l26.4,15.2c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-26.4-15.2
				c-0.3-0.2-0.6-0.6-0.6-0.9C512.6,209,512.9,208.8,513.2,209z"
                      />
                      <path
                        id="XMLID_3480_"
                        class="st10"
                        d="M513.2,218.8l12.6,7.3c0.3,0.2,0.6,0.6,0.6,0.9s-0.3,0.5-0.6,0.3l-12.6-7.3
				c-0.3-0.2-0.6-0.6-0.6-0.9C512.6,218.7,512.9,218.6,513.2,218.8z"
                      />
                      <path
                        id="XMLID_3458_"
                        class="st4"
                        d="M533.3,206l10.5,6c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-10.5-6
				c-0.3-0.2-0.6-0.6-0.6-0.9C532.7,205.9,533,205.8,533.3,206z"
                      />
                      <path
                        id="XMLID_3455_"
                        class="st4"
                        d="M504.7,243.2l26.4,15.2c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-26.4-15.2
				c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,243.1,504.4,243,504.7,243.2z"
                      />
                      <path
                        id="XMLID_3452_"
                        class="st4"
                        d="M533.3,259.7l10.5,6c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-10.5-6
				c-0.3-0.2-0.6-0.6-0.6-0.9C532.7,259.6,533,259.5,533.3,259.7z"
                      />
                      <path
                        id="XMLID_3448_"
                        class="st4"
                        d="M533.3,245.1l10.5,6c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-10.5-6
				c-0.3-0.2-0.6-0.6-0.6-0.9C532.7,245,533,244.9,533.3,245.1z"
                      />
                      <path
                        id="XMLID_3288_"
                        class="st4"
                        d="M533.3,249.9l10.5,6c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-10.5-6
				c-0.3-0.2-0.6-0.6-0.6-0.9C532.7,249.9,533,249.8,533.3,249.9z"
                      />
                      <path
                        id="XMLID_3285_"
                        class="st4"
                        d="M524.3,239.9l5.9,3.4c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.9-3.4
				c-0.3-0.2-0.6-0.6-0.6-0.9C523.8,239.8,524,239.7,524.3,239.9z"
                      />
                      <g id="XMLID_3242_">
                        <path
                          id="XMLID_3244_"
                          class="st4"
                          d="M504.7,194.4l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,194.3,504.4,194.2,504.7,194.4z"
                        />
                        <path
                          id="XMLID_3243_"
                          class="st17"
                          d="M504.7,194.4l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,194.3,504.4,194.2,504.7,194.4z"
                        />
                      </g>
                      <g id="XMLID_3239_">
                        <path
                          id="XMLID_3241_"
                          class="st4"
                          d="M504.7,199.2l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,199.2,504.4,199.1,504.7,199.2z"
                        />
                        <path
                          id="XMLID_3240_"
                          class="st17"
                          d="M504.7,199.2l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,199.2,504.4,199.1,504.7,199.2z"
                        />
                      </g>
                      <g id="XMLID_3236_">
                        <path
                          id="XMLID_3238_"
                          class="st4"
                          d="M504.7,204.1l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,204.1,504.4,204,504.7,204.1z"
                        />
                        <path
                          id="XMLID_3237_"
                          class="st17"
                          d="M504.7,204.1l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,204.1,504.4,204,504.7,204.1z"
                        />
                      </g>
                      <g id="XMLID_3234_">
                        <path
                          id="XMLID_3235_"
                          class="st4"
                          d="M504.7,209l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,209,504.4,208.8,504.7,209z"
                        />
                      </g>
                      <path
                        id="XMLID_3233_"
                        class="st17"
                        d="M504.7,209l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
				c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,209,504.4,208.8,504.7,209z"
                      />
                      <path
                        id="XMLID_3232_"
                        class="st21"
                        d="M513.2,213.9l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
				c-0.3-0.2-0.6-0.6-0.6-0.9S512.9,213.7,513.2,213.9z"
                      />
                      <path
                        id="XMLID_3228_"
                        class="st21"
                        d="M530.7,224l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
				c-0.3-0.2-0.6-0.6-0.6-0.9C530.2,224,530.4,223.9,530.7,224z"
                      />
                      <path
                        id="XMLID_3205_"
                        class="st17"
                        d="M513.2,213.9l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
				c-0.3-0.2-0.6-0.6-0.6-0.9S512.9,213.7,513.2,213.9z"
                      />
                      <path
                        id="XMLID_3204_"
                        class="st17"
                        d="M530.7,224l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
				c-0.3-0.2-0.6-0.6-0.6-0.9C530.2,224,530.4,223.9,530.7,224z"
                      />
                      <g id="XMLID_3201_">
                        <path
                          id="XMLID_3203_"
                          class="st4"
                          d="M504.7,213.9l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,213.8,504.4,213.7,504.7,213.9z"
                        />
                        <path
                          id="XMLID_3202_"
                          class="st17"
                          d="M504.7,213.9l5.2,3c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-5.2-3
					c-0.3-0.2-0.6-0.6-0.6-0.9C504.2,213.8,504.4,213.7,504.7,213.9z"
                        />
                      </g>
                      <g id="XMLID_3198_">
                        <path
                          id="XMLID_3200_"
                          class="st4"
                          d="M522.3,204.5l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C521.7,204.5,522,204.3,522.3,204.5z"
                        />
                        <path
                          id="XMLID_3199_"
                          class="st17"
                          d="M522.3,204.5l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C521.7,204.5,522,204.3,522.3,204.5z"
                        />
                      </g>
                      <g id="XMLID_3196_">
                        <path
                          id="XMLID_3197_"
                          class="st14"
                          d="M519.5,227.3l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9S519.2,227.1,519.5,227.3z"
                        />
                      </g>
                      <g id="XMLID_3194_">
                        <path
                          id="XMLID_3195_"
                          class="st14"
                          d="M528.4,237.3l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C527.8,237.3,528.1,237.2,528.4,237.3z"
                        />
                      </g>
                      <g id="XMLID_3094_">
                        <path
                          id="XMLID_3193_"
                          class="st14"
                          d="M537.1,237.5l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C536.5,237.4,536.8,237.3,537.1,237.5z"
                        />
                      </g>
                      <g id="XMLID_2998_">
                        <path
                          id="XMLID_2999_"
                          class="st10"
                          d="M537.1,256.8l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C536.5,256.7,536.8,256.6,537.1,256.8z"
                        />
                      </g>
                      <g id="XMLID_2995_">
                        <path
                          id="XMLID_2997_"
                          class="st4"
                          d="M513.2,204.1l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9s-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C512.6,204.1,512.9,204,513.2,204.1z"
                        />
                        <path
                          id="XMLID_2996_"
                          class="st17"
                          d="M513.2,204.1l15.4,8.9c0.3,0.2,0.6,0.6,0.6,0.9s-0.3,0.5-0.6,0.3l-15.4-8.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C512.6,204.1,512.9,204,513.2,204.1z"
                        />
                      </g>
                      <g id="XMLID_2918_">
                        <path
                          id="XMLID_2922_"
                          class="st4"
                          d="M530.7,214.3l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C530.2,214.2,530.4,214.1,530.7,214.3z"
                        />
                        <path
                          id="XMLID_2919_"
                          class="st17"
                          d="M530.7,214.3l6.7,3.9c0.3,0.2,0.6,0.6,0.6,0.9c0,0.3-0.3,0.5-0.6,0.3l-6.7-3.9
					c-0.3-0.2-0.6-0.6-0.6-0.9C530.2,214.2,530.4,214.1,530.7,214.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="left_arm">
                  <path
                    class="st22"
                    d="M345.3,217.3c-0.6-0.9-1.8-1.1-2.8-1.5c-1.9-0.7-3.4-2.2-5.3-3c-2.1-0.9-4.5-1-6.7-0.4
		c-0.8,0.2-1.5,0.4-2.2,0.7c-1.4,0.5-2.7,1.3-4,2c-5.3,3.1-9.3,10.2-14.5,13.5c-1.9,1.2-19.6,5.3-21.4,6.4l12.4,11.1
		c2.6-2.1,20.8-7,22-7.7c3.1-1.8,6.2-1.9,9.6-2.4c2.4-0.3,4.7-1.1,7-1.8c0.8-0.2,1.7-0.6,2.1-1.3c0.4-1-0.3-2.2-1.3-2.6
		c-1.1-0.4-2.5-0.3-3.6-0.5c-0.9-0.1-3.3,0.4-3.8-0.5c-0.3-0.5,0.1-1.1,0.4-1.5c2.2-2.7,4.9-5.6,8.3-6.4c1.1-0.3,2.2-0.5,3-1.2
		C345.4,219.6,345.9,218.3,345.3,217.3z"
                  />
                </g>
                <g id="Plant">
                  <g id="Pot_2_">
                    <g id="XMLID_195_">
                      <g id="XMLID_196_">
                        <g id="XMLID_197_">
                          <g id="XMLID_210_">
                            <path
                              id="XMLID_211_"
                              class="st4"
                              d="M462.5,423.8c-10.4-10.7-16.5-54.3-6.4-62.8h51.5c10.1,8.5,4,52.1-6.3,62.8
							c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.4c-0.3,0.3-0.6,0.6-0.9,0.8c-0.1,0.1-0.2,0.1-0.2,0.2
							c-0.3,0.2-0.5,0.4-0.8,0.6c-0.4,0.3-0.9,0.6-1.3,0.9c-8.5,5-22.4,5-30.9,0l0,0l0,0c-0.5-0.3-0.9-0.6-1.4-0.9
							c-0.3-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.3-0.3-0.4-0.4
							C462.7,424,462.6,423.9,462.5,423.8z"
                            />
                          </g>
                          <g id="XMLID_208_" class="st6">
                            <path
                              id="XMLID_209_"
                              class="st14"
                              d="M462.5,423.8c-10.4-10.7-16.5-54.3-6.4-62.8h51.5c10.1,8.5,4,52.1-6.3,62.8
							c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.4c-0.3,0.3-0.6,0.6-0.9,0.8c-0.1,0.1-0.2,0.1-0.2,0.2
							c-0.3,0.2-0.5,0.4-0.8,0.6c-0.4,0.3-0.9,0.6-1.3,0.9c-8.5,5-22.4,5-30.9,0l0,0l0,0c-0.5-0.3-0.9-0.6-1.4-0.9
							c-0.3-0.2-0.5-0.4-0.7-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.1-0.3-0.3-0.4-0.4
							C462.7,424,462.6,423.9,462.5,423.8z"
                            />
                          </g>
                          <g id="XMLID_206_">
                            <path
                              id="XMLID_207_"
                              class="st4"
                              d="M503.2,357.6c11.8,6.9,11.8,18,0,24.8c-11.8,6.9-30.8,6.9-42.6,0
							c-11.8-6.9-11.8-18,0-24.8C472.4,350.8,491.4,350.8,503.2,357.6z"
                            />
                          </g>
                          <g id="XMLID_204_" class="st23">
                            <path
                              id="XMLID_205_"
                              class="st14"
                              d="M503.2,357.6c11.8,6.9,11.8,18,0,24.8c-11.8,6.9-30.8,6.9-42.6,0
							c-11.8-6.9-11.8-18,0-24.8C472.4,350.8,491.4,350.8,503.2,357.6z"
                            />
                          </g>
                          <g id="XMLID_202_">
                            <path
                              id="XMLID_203_"
                              class="st4"
                              d="M497.5,360.9c8.6,5,8.6,13.2,0,18.2c-8.6,5-22.6,5-31.3,0c-8.6-5-8.6-13.2,0-18.2
							C474.9,355.9,488.9,355.9,497.5,360.9z"
                            />
                          </g>
                          <g id="XMLID_200_" class="st24">
                            <path
                              id="XMLID_201_"
                              d="M497.5,360.9c8.6,5,8.6,13.2,0,18.2c-8.6,5-22.6,5-31.3,0c-8.6-5-8.6-13.2,0-18.2
							C474.9,355.9,488.9,355.9,497.5,360.9z"
                            />
                          </g>
                          <g id="XMLID_198_">
                            <path
                              id="XMLID_199_"
                              class="st12"
                              d="M466.3,369.6c8.6-5,22.6-5,31.3,0c2.4,1.4,4.1,3,5.2,4.8c-1.1,1.7-2.8,3.4-5.2,4.8
							c-8.6,5-22.6,5-31.3,0c-2.4-1.4-4.1-3-5.2-4.8C462.2,372.6,463.9,371,466.3,369.6z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Plants_1_">
                      <g id="right_leaf">
                        <g id="XMLID_193_">
                          <path
                            id="XMLID_194_"
                            class="st4"
                            d="M477.7,347.6c-0.6-1.2-1.1-2.4-1.3-3.8c-0.6-3.1,0.2-6.7,2.7-8.7c1-0.8,2.3-1.3,2.8-2.5
						c0.2-0.7,0.1-1.4,0-2.1c-0.5-2.7-1.4-5.4-0.6-8.1c0.7-2.4,2.7-4.3,5-5.4c2.3-1.1,4.8-1.5,7.3-1.9c1.3-0.2,2.7-0.4,3.8-1.1
						c1.4-0.8,1.9-2.2,2.8-3.5c1-1.4,2.3-2.6,3.5-3.8c2.6-2.3,5.9-4.3,9.5-4.1c7.6,0.3,7.4,6.9,5.2,11.8c-2.2,4.9-2.8,6.5,2,11.7
						c4.7,5.1,4.7,11.5-0.9,15c-5.6,3.6-9.3,4-7.1,10.9c2,6.1,3.2,11.3-2.4,17.8c-3.6,4.2-8.6,7.2-14.9,7c-1.8-0.1-5.7-0.9-7.3-1.7
						c-1.7-0.9-0.6-2.2-1.1-3.9c-1.2-3.6-2.5-7.3-3.7-10.9c-1-2.8-1.7-5.9-3-8.6C479.2,350.4,478.4,349,477.7,347.6z"
                          />
                        </g>
                        <g id="XMLID_141_" class="st25">
                          <path
                            id="XMLID_142_"
                            d="M477.7,347.6c-0.6-1.2-1.1-2.4-1.3-3.8c-0.6-3.1,0.2-6.7,2.7-8.7c1-0.8,2.3-1.3,2.8-2.5
						c0.2-0.7,0.1-1.4,0-2.1c-0.5-2.7-1.4-5.4-0.6-8.1c0.7-2.4,2.7-4.3,5-5.4c2.3-1.1,4.8-1.5,7.3-1.9c1.3-0.2,2.7-0.4,3.8-1.1
						c1.4-0.8,1.9-2.2,2.8-3.5c1-1.4,2.3-2.6,3.5-3.8c2.6-2.3,5.9-4.3,9.5-4.1c7.6,0.3,7.4,6.9,5.2,11.8c-2.2,4.9-2.8,6.5,2,11.7
						c4.7,5.1,4.7,11.5-0.9,15c-5.6,3.6-9.3,4-7.1,10.9c2,6.1,3.2,11.3-2.4,17.8c-3.6,4.2-8.6,7.2-14.9,7c-1.8-0.1-5.7-0.9-7.3-1.7
						c-1.7-0.9-0.6-2.2-1.1-3.9c-1.2-3.6-2.5-7.3-3.7-10.9c-1-2.8-1.7-5.9-3-8.6C479.2,350.4,478.4,349,477.7,347.6z"
                          />
                        </g>
                        <g id="XMLID_139_">
                          <path
                            id="XMLID_140_"
                            class="st14"
                            d="M488,375.3c0.2-0.1,0.4-0.3,0.4-0.5c-0.3-37.4,20.3-59.8,20.5-60c0.2-0.2,0.2-0.6,0-0.8
						c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-21.1,22.9-20.8,60.8c0,0.3,0.3,0.6,0.6,0.6C487.9,375.3,487.9,375.3,488,375.3z"
                          />
                        </g>
                        <g id="XMLID_137_">
                          <path
                            id="XMLID_138_"
                            class="st14"
                            d="M494,340.5c0,0,0.1,0,0.1-0.1c6.7-4.2,18.7-7.4,18.8-7.4c0.3-0.1,0.5-0.4,0.4-0.7
						c-0.1-0.3-0.4-0.5-0.7-0.4c-0.5,0.1-12.3,3.2-19.1,7.5c-0.3,0.2-0.3,0.5-0.2,0.8C493.5,340.5,493.8,340.6,494,340.5z"
                          />
                        </g>
                        <g id="XMLID_135_">
                          <path
                            id="XMLID_136_"
                            class="st14"
                            d="M496.1,335.3C496.1,335.3,496.1,335.3,496.1,335.3c0.3-0.1,0.5-0.5,0.3-0.7
						c-2.3-5.8-6-10.2-6.2-10.4c-0.2-0.2-0.6-0.3-0.8-0.1c-0.2,0.2-0.3,0.6-0.1,0.8c0,0,3.7,4.5,6,10.1
						C495.5,335.3,495.8,335.4,496.1,335.3z"
                          />
                        </g>
                      </g>
                      <g id="left_leaf">
                        <path
                          id="XMLID_133_"
                          class="st4"
                          d="M482.7,379.3c0,0-7,2.6-16.4,0.6c-9.4-2-15.2-10.8-15.2-10.8s10.2-4.1,16.1-4.5
					c0,0-9.2-1.6-17.9,0.4c0,0-5.5-2-4.4-11.2c0,0,5.1-3.8,17-2.3c0,0-5.9-4.2-16.3-5.5c-0.8,0,0.3-12,1.3-17.3c1-5.4,3-4.5,7.2-4.1
					c4.2,0.5,16.8,3.3,18.4,6.4c1.6,3.1,0,10.9,0,10.9s3.5-4.3,3.5-10.3c0,0,4.7,1.7,7.3,4c2.5,2.2,3.8,4,4.5,5.7
					c-0.6,4.2-2.6,10.6-5.4,14.2c0,0,6.3-4,7.7-11.5c0,0,5,6.4,5,14.7C495,368.1,490.8,375,482.7,379.3z"
                        />
                        <path
                          id="XMLID_132_"
                          class="st26"
                          d="M482.7,379.3c0,0-7,2.6-16.4,0.6c-9.4-2-15.2-10.8-15.2-10.8s10.2-4.1,16.1-4.5
					c0,0-9.2-1.6-17.9,0.4c0,0-5.5-2-4.4-11.2c0,0,5.1-3.8,17-2.3c0,0-5.9-4.2-16.3-5.5c-0.8,0,0.3-12,1.3-17.3c1-5.4,3-4.5,7.2-4.1
					c4.2,0.5,16.8,3.3,18.4,6.4c1.6,3.1,0,10.9,0,10.9s3.5-4.3,3.5-10.3c0,0,4.7,1.7,7.3,4c2.5,2.2,3.8,4,4.5,5.7
					c-0.6,4.2-2.6,10.6-5.4,14.2c0,0,6.3-4,7.7-11.5c0,0,5,6.4,5,14.7C495,368.1,490.8,375,482.7,379.3z"
                        />
                        <g id="XMLID_130_">
                          <path
                            id="XMLID_131_"
                            class="st14"
                            d="M484,378.5c-0.2-0.1-0.3-0.2-0.3-0.5c-1.4-17.9-21.4-45.7-30.2-51.4
						c-0.2-0.2-0.3-0.5-0.2-0.7c0.2-0.3,0.5-0.3,0.7-0.2c9,5.7,29.3,34,30.7,52.2c0,0.3-0.2,0.6-0.5,0.6
						C484.1,378.5,484.1,378.5,484,378.5z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Character">
                  <g id="Character_1_">
                    <g id="XMLID_179_">
                      <g id="XMLID_183_">
                        <g id="XMLID_191_">
                          <path
                            id="XMLID_192_"
                            class="st22"
                            d="M304.3,368.5c0-0.2-3.2-11.8-3.2-11.8s1,1.1,7.7-0.7c3.4-0.9,7.3-3.1,7.3-3.1l1.9,8.5
						c1.8,3.3,7.7,1.8,14.2,1c1.1-0.1,2.2-0.3,3.3-0.5l0.1,0c3.5-0.2,7,1.9,3.4,4.9c-3.6,3-9.6,4.1-15.2,5.1
						C317.2,373.2,305.5,375.1,304.3,368.5z"
                          />
                        </g>
                        <g id="XMLID_184_">
                          <path
                            id="XMLID_190_"
                            class="st9"
                            d="M350.9,367.5c0,0,0.7,1.3,0.5,3.6c-0.1,1.3-3.3,5.7-9,8.4c-5.6,2.7-12.6,2.9-15.9,3.8
						c-3.3,0.9-6.3,2.8-10.1,3.8c-4,1-7.7,1.4-11.5-1.1c-0.7-1.1-1.5-3.6-1.3-3.9C304.2,381.3,350.9,367.5,350.9,367.5z"
                          />
                          <path
                            id="XMLID_189_"
                            class="st4"
                            d="M319.1,362.6c2.5,1.7,7.6,0.5,13.2-0.1c5.2-0.6,11.1-2.4,14.7-1.2
						c3.6,1.2,6.5,5.4,1.7,10.8c-1.4,1.6-4.3,4.3-6.7,5.1c-6,2.1-11.3,2.3-15.2,3.3c-5,1.2-7.7,3.4-12.4,4c-6,0.7-9.8-0.8-10.8-2.4
						c-1-1.6-2-14.5,0.6-14.2c0,0,1.1,4.2,10.9,0.1C319.3,366.1,319.3,363.5,319.1,362.6z"
                          />
                          <path
                            id="XMLID_188_"
                            class="st7"
                            d="M319.1,362.6c2.5,1.7,7.6,0.5,13.2-0.1c5.2-0.6,11.1-2.4,14.7-1.2
						c3.6,1.2,6.5,5.4,1.7,10.8c-1.4,1.6-4.3,4.3-6.7,5.1c-6,2.1-11.3,2.3-15.2,3.3c-5,1.2-7.7,3.4-12.4,4c-6,0.7-9.8-0.8-10.8-2.4
						c-1-1.6-2-14.5,0.6-14.2c0,0,1.1,4.2,10.9,0.1C319.3,366.1,319.3,363.5,319.1,362.6z"
                          />
                          <path
                            id="XMLID_187_"
                            class="st27"
                            d="M335.7,369.5c0,0-0.6-5.4-4.6-6.9c0,0-1.3-0.6-2.2,0.3c0,0,3.7,0.5,5,6.9
						c0,0,0.2,0.4,1,0.3C335.1,370.1,335.7,369.9,335.7,369.5z"
                          />
                          <path
                            id="XMLID_186_"
                            class="st27"
                            d="M325.5,363.3c0,0,0-0.2,0.9-0.4c0.7-0.1,1.1,0.1,1.1,0.1s3,1.3,3.6,7c0,0,0,0.7-0.9,0.9
						c-0.9,0.2-1.1-0.6-1.1-0.6S328.5,364.4,325.5,363.3z"
                          />
                          <path
                            id="XMLID_185_"
                            class="st27"
                            d="M322.2,363.4c0,0,0.2-0.3,0.9-0.3c0.9,0,1.1,0.3,1.1,0.3s2.1,2.2,2.2,6.9
						c0,0,0.1,0.8-1,0.9c-1.1,0.1-1-0.8-1-0.8S324,364.4,322.2,363.4z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_180_">
                        <g id="XMLID_181_">
                          <path
                            id="XMLID_182_"
                            class="st2"
                            d="M300.2,289.7c4.9,14.7,17.9,65.5,17.9,65.5s-8.5,7.6-17.6,4.1l-21.9-63.2L300.2,289.7z"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_166_">
                      <g id="XMLID_169_">
                        <g id="XMLID_177_">
                          <path
                            id="XMLID_178_"
                            class="st22"
                            d="M338.2,383.6c-0.9-1.5-4.1-12-4.1-12s3.8,2.5,10.6,1.2c3.5-0.6,6.9-4.2,6.9-4.2l2.3,10.1
						c1.5,3.4,7.1,2.6,13.6,2.4c1.1,0,2.2-0.1,3.4-0.2l0.1,0c3.5,0.1,6.8,2.5,3,5.1c-3.9,2.7-9.9,3.3-15.5,3.9
						C351.6,390.6,341.6,389.4,338.2,383.6z"
                          />
                        </g>
                      </g>
                      <g id="XMLID_167_">
                        <path
                          id="XMLID_168_"
                          class="st3"
                          d="M334.7,307.1c3.9,11.9,13.7,45.7,18.2,63c0,0-8.1,7.3-18.5,6.6l-25.1-65.1l10-6.2
					L334.7,307.1z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_144_">
                      <g id="XMLID_164_">
                        <path
                          id="XMLID_165_"
                          class="st3"
                          d="M252.1,469.5c0.9,0,1.6-0.7,1.6-1.6V363.3c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6
					v104.6C250.5,468.8,251.2,469.5,252.1,469.5z"
                        />
                      </g>
                      <g id="XMLID_162_">
                        <path
                          id="XMLID_163_"
                          class="st3"
                          d="M187.4,431.5c0.6,0,1.3-0.4,1.5-1l32.1-81.2c0.3-0.8-0.1-1.8-0.9-2.1
					c-0.8-0.3-1.8,0.1-2.1,0.9l-32.1,81.2c-0.3,0.8,0.1,1.8,0.9,2.1C187,431.4,187.2,431.5,187.4,431.5z"
                        />
                      </g>
                      <g id="XMLID_160_">
                        <path
                          id="XMLID_161_"
                          class="st3"
                          d="M191.2,421.7c0.4,0,0.9-0.2,1.2-0.5l59.7-63.2l59.6,62.9c0.6,0.7,1.6,0.7,2.3,0.1
					c0.7-0.6,0.7-1.6,0.1-2.3l-60.8-64.1c-0.3-0.3-0.7-0.5-1.2-0.5c0,0,0,0,0,0c-0.4,0-0.9,0.2-1.2,0.5L190,419
					c-0.6,0.7-0.6,1.7,0.1,2.3C190.4,421.6,190.8,421.7,191.2,421.7z"
                        />
                      </g>
                      <g id="XMLID_158_">
                        <path
                          id="XMLID_159_"
                          class="st3"
                          d="M316.8,431.5c0.2,0,0.4,0,0.6-0.1c0.8-0.3,1.2-1.3,0.9-2.1l-32.1-81.2
					c-0.3-0.8-1.3-1.2-2.1-0.9c-0.8,0.3-1.2,1.3-0.9,2.1l32.1,81.2C315.5,431.1,316.1,431.5,316.8,431.5z"
                        />
                      </g>
                      <g id="XMLID_156_">
                        <path
                          id="XMLID_157_"
                          class="st4"
                          d="M317.6,327.4c1.2-6.6-4.2-11.7-14.2-18.5c-10.1-6.8-22.9-13.5-29.1-16.4
					c-8.4-3.9-17-3.5-27.8,2c-15,7.7-60,35-60,35s67.3,38,80.3,36.3c13-1.7,45.3-22.8,47.9-30.2
					C317.2,328.4,317.6,327.4,317.6,327.4z"
                        />
                      </g>
                      <g id="XMLID_154_" class="st7">
                        <path
                          id="XMLID_155_"
                          d="M317.6,327.4c1.2-6.6-4.2-11.7-14.2-18.5c-10.1-6.8-22.9-13.5-29.1-16.4c-8.4-3.9-17-3.5-27.8,2
					c-15,7.7-60,35-60,35s67.3,38,80.3,36.3c13-1.7,45.3-22.8,47.9-30.2C317.2,328.4,317.6,327.4,317.6,327.4z"
                        />
                      </g>
                      <g id="XMLID_152_">
                        <path
                          id="XMLID_153_"
                          class="st4"
                          d="M260.2,357.9c9.7,8.7,19.8,1.2,35.7-9.2c18.1-11.7,21.6-21.3,21.6-21.3s0,7.2-1.3,10.6
					c-1.3,3.4-4.3,7.2-17.3,16.2c-13,9-22.5,13.5-31.3,15.2l-6.9-1"
                        />
                      </g>
                      <path
                        id="XMLID_151_"
                        class="st6"
                        d="M295.9,348.8c18.1-11.7,21.6-21.3,21.6-21.3s0,7.2-1.3,10.6c-1.3,3.4-4.3,7.2-17.3,16.2
				c-13,9-22.5,13.5-31.3,15.2l-6.9-1l-0.5-10.5C269.9,366.7,280,359.2,295.9,348.8z"
                      />
                      <g id="XMLID_149_">
                        <path
                          id="XMLID_150_"
                          class="st4"
                          d="M189.9,418.7l-5,11.4c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.2,0.3,0.6,0.6,0.8
					c0.7,0.5,1.9,0.5,2.7,0.1c0.3-0.1,0.5-0.3,0.5-0.5c0,0,0,0,0,0l0-0.2l4.6-10.7L189.9,418.7z"
                        />
                      </g>
                      <g id="XMLID_147_">
                        <path
                          id="XMLID_148_"
                          class="st4"
                          d="M314.6,419.2l4.2,11.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.2-0.3,0.5-0.6,0.7
					c-0.7,0.4-1.9,0.4-2.6,0c-0.3-0.2-0.4-0.3-0.5-0.5c0,0,0,0,0,0l0-0.2l-3.9-10.5L314.6,419.2z"
                        />
                      </g>
                      <g id="XMLID_145_">
                        <path
                          id="XMLID_146_"
                          class="st4"
                          d="M250.2,459.8l0,9.3c0,0.3,0.2,0.6,0.5,0.8c0.7,0.4,1.9,0.4,2.7,0c0.4-0.2,0.5-0.5,0.5-0.8
					v-9.3C252.7,459.2,251.5,459.2,250.2,459.8z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <g id="XMLID_125_">
                          <g id="XMLID_128_">
                            <path
                              id="XMLID_143_"
                              class="st2"
                              d="M213.4,288.2c3.8,4.5,38.7-5.6,48-6.8c14.9-2,27-3.5,32.1-1.9c5.1,1.6,7.2,4.2,8.8,10.2
							c1.8,7.1,2.8,10.8,2.8,10.8s-24.8,9.5-44.3,11.8C241.2,314.6,213.4,288.2,213.4,288.2z"
                            />
                          </g>
                          <g id="XMLID_126_">
                            <path
                              id="XMLID_127_"
                              class="st3"
                              d="M206.9,294.8c-3.9,14.7-7.3,31.9,1.6,41.3c6,6.3,36.3,22.7,55.8,16.3
							c19.2-6.4,59.1-36,63.1-38.9c4-3,7.6-5,7.3-6.3c-5.4-17.8-13.2-19.2-27.8-16c-17.8,3.9-56.9,19.9-56.9,19.9L206.9,294.8z"
                            />
                          </g>
                        </g>
                      </g>
                      <g id="XMLID_122_">
                        <g id="XMLID_123_">
                          <polygon
                            id="XMLID_124_"
                            class="st22"
                            points="299.8,190.7 281.9,215.2 260.2,204.9 277.9,183 					"
                          />
                        </g>
                      </g>
                      <g id="Head">
                        <g id="XMLID_117_">
                          <g id="XMLID_120_">
                            <path
                              id="XMLID_121_"
                              class="st28"
                              d="M312,171.2c0,0,5.5,7.9,4.9,8.8c-0.6,0.8-4.5,1.6-4.5,1.6L312,171.2z"
                            />
                          </g>
                          <g id="XMLID_118_">
                            <path
                              id="XMLID_119_"
                              class="st22"
                              d="M296.8,149.2c10.8,2,14,6.7,15.4,23.7c1.5,17.8-0.2,23.5-2.5,25.9
							c-1.5,1.7-10.3,2.2-14.8,1c-5.6-1.4-18.2-5.6-24.3-14.4c-7.2-10.4-9.3-24.7-2-30.7C278.7,146.3,292.4,148.4,296.8,149.2z"
                            />
                          </g>
                          <path
                            class="st28"
                            d="M295.4,200c4.7,1.1,11.7,0.4,13.8-0.8c-0.2,0.1-3.5,0.9-7.8-0.3c-4.4-1.2-7.2-3.2-7.2-3.2L295.4,200z"
                          />
                        </g>
                        <g id="XMLID_110_">
                          <g id="XMLID_114_">
                            <g id="XMLID_115_">
                              <path
                                id="XMLID_116_"
                                class="st9"
                                d="M314.2,156.2C314.2,156.2,314.2,156.2,314.2,156.2c-3,2.1-6.7,3-10.4,3.5
								c0.3,2.9-0.6,5.8-1.5,8.5c-0.6,1.9-2.9,7.9-5.3,4.6c-1.8-2.4-3.4-4.6-6.8-4c-5.6,1-6.2,8.1-2.5,12.4c3.6,4.3,6.9,2.5,6.9,2.5
								s0.5,7.7-3.5,13.1c0,0-6.1,3.7-14.6,0.9c-5.4-1.8-13.1-8.5-16-13.8c-7.8-14.5-7.9-28.4,1.6-32.7c0.9-7,6.3-10.2,12.5-11.8
								c8.6-2.2,17.5,3.2,25.8-0.3c1.7-0.7,3.4-1.8,5.1-2.5c1-0.4,2.2-0.6,3.1,0c0.8,0.5,1.1,1.5,1.3,2.4c0.4,1.7,0.5,3.5-0.3,5
								c1.3-0.6,2.5-1.2,3.9-1.4c1.4-0.2,2.9,0,3.9,0.9c1,0.8,1.5,2.1,1.6,3.4C319.4,150.6,317.2,154.1,314.2,156.2z"
                              />
                            </g>
                          </g>
                          <g id="XMLID_111_">
                            <g id="XMLID_112_">
                              <path
                                id="XMLID_113_"
                                class="st9"
                                d="M262,153.6l-6.5-3.6c1-1.8,3.3-2.4,5.1-1.4C262.4,149.5,263,151.8,262,153.6z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <path
                        class="st4"
                        d="M266.3,196.5c-13.2-7.2-25.2-2.6-29.6,6.7c-3.9,8.4-12.2,40.8-12.2,40.8
				C228.5,251.5,266.3,196.5,266.3,196.5z"
                      />
                      <g>
                        <path
                          class="st4"
                          d="M264.3,199.1l2-2.6c-14.9-7.1-28.3,2.2-40,20.5c-12.7,19.7-19.3,48-19.4,77.9c0,0,2.5,13.4,19.5,20.1
					c18,7.1,43.1,4.1,50.9-14.6c0,0,0.2-18.6,5.8-27.8c3.7-5.9,12.2-19.1,18.6-28.6c5.5-8.2,9.8-27-1.7-33.8c-3.4-2-4.8-2.6-9.4-4.8
					C280.1,208.9,263,203.3,264.3,199.1z"
                        />
                        <path
                          class="st29"
                          d="M264.4,199.2l1.9-2.7c-14.9-7.1-28.3,2.2-40,20.5c-12.7,19.7-19.3,48-19.4,77.9c0,0,2.5,13.4,19.5,20.1
					c18,7.1,43.1,4.1,50.9-14.6c0,0,0.2-18.6,5.8-27.8c3.7-5.9,12.2-19.1,18.6-28.6c5.5-8.2,9.8-27-1.7-33.8c-3.4-2-4.8-2.6-9.4-4.8
					C280.1,208.9,263,203.4,264.4,199.2z"
                        />
                        <path
                          class="st30"
                          d="M306.5,232.2c0.8-3.6,1-7.4,0.5-10.9v0c-2.7,6.3-6.5,12.2-11.3,17.2c-6.1,6.4-13.6,11.4-19.2,18.2
					c-6,7.2-10.2,15.9-15.2,23.7c-2,3.1-4.7,9.2-8.5,9.5c-4.5,0.3-8.9-5.2-11.4-9.1c-3.9-6.1-7.3-13.7-12.2-19
					c-4.3-4.7-10.9-10.7-16.8-7.9h0c-0.4,1.6-0.8,3.1-1.2,4.7c-0.4,1.6-0.8,3.2-1.1,4.8c0,0.1,0,0.1,0,0.2c-0.3,1.6-0.6,3.3-0.9,4.9
					c0,0.1,0,0.2,0,0.3c-0.3,1.6-0.5,3.2-0.8,4.9c-1,7-1.5,14.2-1.5,21.4c0,0,2.5,13.4,19.5,20.1c18,7.1,43.1,4.1,50.9-14.6
					c0,0,0.2-18.6,5.8-27.8c0.6-0.9,1.3-2,2-3.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0.6-1,1.4-2.1,2.1-3.3c0-0.1,0.1-0.1,0.1-0.2
					c0,0,0-0.1,0.1-0.1c1.6-2.5,3.4-5.3,5.3-8.2c0.3-0.4,0.6-0.9,0.9-1.3c1.8-2.8,3.7-5.6,5.4-8.2c0,0,0,0,0,0
					c0.6-0.9,1.2-1.8,1.7-2.6c0.2-0.4,0.5-0.7,0.7-1.1c0,0,0,0,0,0c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.2,0.2-0.3
					c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.6,0.4-0.9c0-0.1,0.1-0.2,0.1-0.2c0.2-0.4,0.4-0.8,0.6-1.2
					c0,0,0,0,0,0c0,0,0,0,0-0.1c0.2-0.4,0.3-0.8,0.5-1.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.4-1.1c0,0,0-0.1,0-0.1
					c0,0,0-0.1,0-0.1c0.2-0.7,0.5-1.4,0.7-2.1c0,0,0,0,0,0C306.2,233.7,306.4,233,306.5,232.2C306.5,232.2,306.5,232.2,306.5,232.2
					C306.5,232.2,306.5,232.2,306.5,232.2z"
                        />
                      </g>
                    </g>
                    <g id="XMLID_105_">
                      <path
                        id="XMLID_109_"
                        class="st4"
                        d="M260.7,368.4c-5.5-0.3-9.8-4.3-22.9-9.8c-24.2-10.1-42.3-18.1-49.2-30.8
				c-6.9-12.7-11.8-49.2-10.1-65c0.2-2.1,0.6-3.8,1.2-5.2l-1.7-0.2l4.4-3.7l0,0c4.5-3.7,11.9-0.4,17.4,2.6c15.8,8.6,28.2,17,38,47.2
				c7.4,22.7,12.2,43.4,22.4,54.5"
                      />
                      <path
                        id="XMLID_108_"
                        class="st24"
                        d="M179.8,257.6l-1.7-0.2l4.4-3.7l0,0c4.5-3.7,11.9-0.4,17.4,2.6c15.8,8.6,28.2,17,38,47.2
				c7.4,22.7,12.2,43.4,22.4,54.5l0.5,10.5c-5.5-0.3-9.8-4.3-22.9-9.8c-24.2-10.1-42.3-18.1-49.2-30.8c-6.9-12.7-11.8-49.2-10.1-65
				C178.8,260.8,179.2,259,179.8,257.6z"
                      />
                      <g id="XMLID_106_">
                        <path
                          id="XMLID_107_"
                          class="st4"
                          d="M195.3,260.1c15.8,8.6,28.2,17,38,47.2c9.8,30.2,15.1,56.9,34.4,62.1c0,0-8.7,3.5-35.4-7.8
					c-8.5-3.6-18.8-10.1-25.6-13.9c-13.3-7.4-21.3-13.6-22.6-15.8c-7-12.6-11.8-49.2-10.1-65C175.7,250.9,187.4,255.8,195.3,260.1z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="right_shoe">
                  <g id="XMLID_170_">
                    <path
                      id="XMLID_176_"
                      class="st9"
                      d="M389.9,384.7c0,0,0.7,1.5,0.3,3.9c-0.2,1.4-3.9,5.9-10.2,8.3c-6.2,2.5-13.7,2.2-17.3,3
			c-3.6,0.8-6.9,2.6-11.1,3.3c-4.3,0.8-8.3,1-12.3-2c-0.7-1.2-1.4-4-1.1-4.2C338.9,396.3,389.9,384.7,389.9,384.7z"
                    />
                    <path
                      id="XMLID_175_"
                      class="st4"
                      d="M356.2,377.1c0.4,0.3,0.8,0.6,1.2,0.8c0.4,0.2,0.9,0.3,1.4,0.3c1.1,0.2,2.2,0.2,3.3,0.2
			c2.7,0,5.5-0.3,8.2-0.5c5.6-0.3,12.1-1.8,15.8-0.3c3.7,1.6,7,6.3,1.5,11.7c-1.6,1.6-4.9,4.2-7.3,5.1c-6.4,2.4-12.9,1.6-17.1,2.4
			c-5.4,1-8.5,3.2-13.5,3.4c-6.5,0.3-10.4-1.6-11.4-3.4c-0.6-1,0.3-6-0.3-10c-0.6-3.4-0.3-5.7,0.9-5.5c0.2,0.4,0.6,0.9,0.9,1.2
			c0.4,0.5,0.8,0.9,1.4,1.2c0.7,0.4,1.6,0.7,2.4,0.9c1.5,0.4,2.9,0.8,4.5,0.8c0.4,0,0.9-0.1,1.2-0.3c0.5-0.3,0.6-0.9,0.8-1.5
			c0.1-0.5,0.3-1,0.4-1.5c0.1-0.3,0.1-0.5,0.3-0.8c0.2-0.3,0.5-0.4,0.8-0.6c0.9-0.5,1.9-0.9,2.5-1.8c0.5-0.7,0.7-1.5,0.8-2.3
			c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.5,0,0.6,0.1C355.8,376.8,356,377,356.2,377.1z"
                    />
                    <path
                      id="XMLID_174_"
                      class="st7"
                      d="M356.2,377.1c0.4,0.3,0.8,0.6,1.2,0.8c0.4,0.2,0.9,0.3,1.4,0.3c1.1,0.2,2.2,0.2,3.3,0.2
			c2.7,0,5.5-0.3,8.2-0.5c5.6-0.3,12.1-1.8,15.8-0.3c3.7,1.6,7,6.3,1.5,11.7c-1.6,1.6-4.9,4.2-7.3,5.1c-6.4,2.4-12.9,1.6-17.1,2.4
			c-5.4,1-8.5,3.2-13.5,3.4c-6.5,0.3-10.4-1.6-11.4-3.4c-0.6-1,0.3-6-0.3-10c-0.6-3.4-0.3-5.7,0.9-5.5c0.2,0.4,0.6,0.9,0.9,1.2
			c0.4,0.5,0.8,0.9,1.4,1.2c0.7,0.4,1.6,0.7,2.4,0.9c1.5,0.4,2.9,0.8,4.5,0.8c0.4,0,0.9-0.1,1.2-0.3c0.5-0.3,0.6-0.9,0.8-1.5
			c0.1-0.5,0.3-1,0.4-1.5c0.1-0.3,0.1-0.5,0.3-0.8c0.2-0.3,0.5-0.4,0.8-0.6c0.9-0.5,1.9-0.9,2.5-1.8c0.5-0.7,0.7-1.5,0.8-2.3
			c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.5,0,0.6,0.1C355.8,376.8,356,377,356.2,377.1z"
                    />
                    <path
                      id="XMLID_173_"
                      class="st27"
                      d="M373.5,385.8c0,0-0.2-5.9-4.4-7.7c0,0-1.3-0.7-2.4,0.2c0,0,4,0.8,4.8,7.8
			c0,0,0.2,0.5,1.1,0.4C372.8,386.3,373.5,386.3,373.5,385.8z"
                    />
                    <path
                      id="XMLID_172_"
                      class="st27"
                      d="M362.9,378.5c0,0,0-0.2,1-0.3c0.7-0.1,1.2,0.2,1.2,0.2s3.1,1.6,3.3,7.7c0,0-0.1,0.7-1.1,0.9
			c-1,0.1-1.1-0.7-1.1-0.7S366,379.8,362.9,378.5z"
                    />
                    <path
                      id="XMLID_171_"
                      class="st27"
                      d="M359.5,378.4c0,0,0.2-0.4,1-0.3c1,0.1,1.2,0.4,1.2,0.4s2.1,2.5,1.8,7.6c0,0,0,0.9-1.2,0.9
			c-1.2,0-1-1-1-1S361.3,379.5,359.5,378.4z"
                    />
                  </g>
                </g>
                <g id="right_arm">
                  <g>
                    <path
                      class="st22"
                      d="M371,250.9c-1,1.1-2.1,2.1-3.2,3.1c-4.7,4-10.1,5.8-16.4,9.6c-5.1,3.1-13,7.6-19.6,11.4
			c-7.5,4.3-16.4,7.8-21.2,9c-6.1,1.6-9.5,1.6-12.8-8.2c-1.6-4.8-2.8-9.7-4.2-14.6c-2-7.3-4-14.5-6-21.8c0,0-7.4-20.1,10.9-29.5
			c7.7,4.7,10.6,6.6,12.8,16.1c2.6,11.3,6.7,33.6,6.7,33.6c6.4-2,12.7-4.5,18.8-7.3c2.3-1.1,5.1-1.8,6.8-3.7c1.1-1.3,2.1-2.8,3-4.4
			c0.8-1.4,1.4-2.8,2.3-4.1c1.6-2.4,4-4.4,6.1-6.3c0.4-0.4,0.9-0.8,1.5-0.8c0.6,0,1.1,0.4,1.4,0.9c0.6,1.2,0,2.3-0.4,3.5
			c-0.4,1.3-0.9,2.7-1.3,4c4.7-2.6,8.7-6,11.8-10.4c0.3-0.4,0.6-0.8,0.9-1.1c0.4-0.3,0.9-0.5,1.3-0.4c1.2,0.2,2.3,2.2,2.4,3.3
			c0,1.1-0.3,2.3,0,3.4c0.2,1.1,0.9,1.9,1.2,3c0.3,1,0.5,2.2,0.6,3.3c0.2,2.4-0.8,4.7-2,6.7C372,249.7,371.5,250.3,371,250.9z"
                    />
                    <path
                      class="st4"
                      d="M297.5,209.1c6.8,3,11.6,6.3,14.4,16.8c2.8,10.6,5.7,27.4,5.7,27.4s0,3.5-8.5,8.2c-8.5,4.6-16.2,1.7-16.2,1.7
			l-5.7-20.4C287.2,242.8,276.9,215.5,297.5,209.1z"
                    />
                  </g>
                </g>
                <g id="Speech_Bubble">
                  <g id="Speech_Bubble_2_">
                    <g id="XMLID_1_">
                      <path
                        id="XMLID_12_"
                        class="st4"
                        d="M286.6,116.5l-4-7.5l3.2-9.2v-5.6l-9.8,5.6v5.6l4.2,7.3c0.1,0.1,0.2,0.2,0.3,0.3l0,0
				L286.6,116.5z"
                      />
                      <path
                        id="XMLID_11_"
                        class="st31"
                        d="M286.6,116.5l-4-7.5l3.2-9.2v-5.6l-9.8,5.6v5.6l4.2,7.3c0.1,0.1,0.2,0.2,0.3,0.3l0,0
				L286.6,116.5z"
                      />
                      <path
                        id="XMLID_10_"
                        class="st4"
                        d="M309.9,49.3c0-1.9-1.3-4.1-2.9-5.1l-2.4-1.4c-1.6-0.9-4.2-0.9-5.9,0L262.2,64
				c-1.6,0.9-2.9,3.2-2.9,5.1v40.2c0,1.9,1.3,4.1,2.9,5.1l2.4,1.4c1.6,0.9,4.2,0.9,5.9,0l11.8-6.8l4.2,7.3c0.3,0.5,1,0.4,1.1-0.1
				l4.4-12.8l15.1-8.7c1.6-0.9,2.9-3.2,2.9-5.1L309.9,49.3z"
                      />
                      <path
                        id="XMLID_9_"
                        class="st31"
                        d="M259.3,109.4c0,1.9,1.3,4.1,2.9,5.1l2.4,1.4c1.5,0.8,3.7,0.9,5.4,0.2
				c-1.4,0.5-2.4-0.3-2.4-1.9l0-40.2c0-0.9,0.3-2,0.9-2.9l-8.2-4.8c-0.5,0.9-0.9,2-0.9,2.9V109.4z"
                      />
                      <path
                        id="XMLID_8_"
                        class="st15"
                        d="M309.8,48.5c-0.3-1.2-1.5-1.6-2.8-0.9l-36.6,21.2c-0.8,0.5-1.5,1.3-2.1,2.2l-8.2-4.8
				c0.5-0.9,1.3-1.7,2.1-2.2l36.6-21.2c1.6-0.9,4.2-0.9,5.9,0l2.4,1.4C308.4,45.1,309.6,46.8,309.8,48.5z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      class="st32"
                      d="M291.3,91.3l-3.6,1.8c-0.3,0.1-0.6,0.7-0.6,1.2c0,0.5,0.2,0.8,0.5,0.7l3.6-1.9c0.3-0.1,0.5-0.6,0.5-1.1
			C291.7,91.4,291.5,91.1,291.3,91.3z"
                    />
                    <path
                      class="st32"
                      d="M288.2,97.9c0,0.8,0.3,1.2,0.8,0.9c0.5-0.3,0.9-1.1,1-2c0.6-0.4,1.1-1.6,1.2-2.6l-4.1,2.2
			C287.1,97.6,287.6,98.3,288.2,97.9z"
                    />
                    <path
                      class="st32"
                      d="M291.5,67.7c0.4,0,0.7-0.5,0.7-1.2l0.2-2.8c0.1-0.7-0.2-1.2-0.5-1.3c-0.4,0-0.7,0.5-0.7,1.2l-0.2,2.9
			C290.9,67.1,291.2,67.7,291.5,67.7z"
                    />
                    <path
                      class="st32"
                      d="M286.8,69.6c0.1,0.4,0.3,0.5,0.6,0.5c0.2,0,0.3-0.1,0.5-0.3c0.4-0.4,0.5-1.2,0.3-1.8l-0.8-2.5
			c-0.2-0.6-0.7-0.8-1.1-0.3c-0.4,0.4-0.5,1.3-0.3,1.9L286.8,69.6z"
                    />
                    <path
                      class="st32"
                      d="M284.5,74.3l-1.8-0.8c-0.5-0.2-1,0.3-1.2,1.1c-0.2,0.8,0,1.6,0.4,1.7l1.9,0.7c0.1,0,0.2,0,0.3,0
			c0.4-0.1,0.7-0.5,0.9-1.1C285,75.2,284.9,74.5,284.5,74.3z"
                    />
                    <path
                      class="st32"
                      d="M296.2,74.9c0.1,0,0.1,0,0.2-0.1l1.2-0.9c0.3-0.2,0.5-0.8,0.4-1.4c0-0.5-0.3-0.8-0.6-0.6l-1.2,0.9
			c-0.3,0.2-0.5,0.9-0.4,1.4C295.8,74.7,296,75,296.2,74.9z"
                    />
                    <path
                      class="st32"
                      d="M295.4,65.9l-0.9,2.1c-0.2,0.5-0.2,1.2,0,1.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.4-0.2,0.5-0.5l0.9-2.1
			c0.2-0.5,0.2-1.2,0-1.5C296,65.3,295.6,65.4,295.4,65.9z"
                    />
                    <path
                      class="st32"
                      d="M295,77.4c0.4-4.4-1.2-7.9-3.6-7.8c-2.7,0.1-5.5,4.5-5.9,9.9c-0.2,2.3,0.1,4.2,0.7,5.6
			c0.5,1.2,1,2.5,1.4,3.8l-0.2,3l4.4-2.1l0.2-2.7c0.5-1.5,1.2-3.2,1.7-4.5C294.4,81,294.8,79.2,295,77.4z"
                    />
                  </g>
                </g>
                <g id="ability">
                  <g>
                    <g id="Text_Box_2_">
                      <g id="_x7B__x7D__2_">
                        <g id="Speech_Bubble_3_">
                          <g id="Speach_Bubble_1_">
                            <g id="XMLID_13_">
                              <path
                                id="XMLID_15_"
                                class="st9"
                                d="M185.8,46.2l-53.9,31c-0.7,0.4-1.3,1.5-1.3,2.3v30.1c0,0.9,0.6,1.9,1.3,2.3l5.3,3
								c0.7,0.4,1.9,0.4,2.7,0l53.9-31c0.7-0.4,1.3-1.5,1.3-2.3V51.6c0-0.9-0.6-1.9-1.3-2.3l-5.3-3
								C187.8,45.8,186.6,45.8,185.8,46.2z"
                              />
                              <path
                                id="XMLID_14_"
                                class="st3"
                                d="M195.1,51.3c-0.1-0.7-0.7-0.9-1.3-0.5l-53.9,31c-0.4,0.2-0.7,0.6-0.9,1l-8-4.6
								c0.2-0.4,0.6-0.7,0.9-1l53.9-31c0.7-0.4,1.9-0.4,2.7,0l5.3,3C194.5,49.6,195.1,50.5,195.1,51.3z"
                              />
                              <g>
                                <path
                                  class="st2"
                                  d="M193.8,50.8l-53.9,31c-0.7,0.4-1.3,1.5-1.3,2.3v30.1c0,0.9,0.6,1.2,1.3,0.8l53.9-31
									c0.7-0.4,1.3-1.5,1.3-2.3V51.6C195.2,50.7,194.6,50.4,193.8,50.8z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon
                          class="st32"
                          points="159.5,100.4 159.2,100.6 159.2,74.3 159.5,74.1 				"
                        />
                        <polygon
                          class="st32"
                          points="159.9,100.7 158.8,101.4 158.8,74 159.9,73.3 				"
                        />
                      </g>
                      <g>
                        <polygon
                          class="st32"
                          points="159.2,100.6 159.2,100.2 180.2,88.2 180.2,88.6 				"
                        />
                        <polygon
                          class="st32"
                          points="180.7,88.9 158.8,101.4 158.8,99.9 180.7,87.4 				"
                        />
                      </g>
                      <g>
                        <polygon
                          class="st32"
                          points="166,94.1 161.3,96.8 161.3,78.2 166,75.6 				"
                        />
                      </g>
                      <g>
                        <polygon
                          class="st32"
                          points="172.3,90.6 167.7,93.2 167.7,72.1 172.3,69.6 				"
                        />
                      </g>
                      <g>
                        <polygon
                          class="st32"
                          points="178.7,86.9 174.1,89.5 174.1,81.7 178.7,79.2 				"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="ability">
                  <g>
                    <g id="Text_Box_4_">
                      <g id="_x7B__x7D__5_">
                        <g id="Speech_Bubble_5_">
                          <g id="Speach_Bubble_4_">
                            <g id="XMLID_22_">
                              <path
                                id="XMLID_24_"
                                class="st9"
                                d="M185.8,108l-55.4,31.9c-0.8,0.4-1.4,1.5-1.4,2.4v30.9c0,0.9,0.6,1.9,1.4,2.4l5.5,3.1
								c0.8,0.4,2,0.4,2.8,0l55.4-31.9c0.8-0.4,1.4-1.5,1.4-2.4v-30.9c0-0.9-0.6-1.9-1.4-2.4l-5.5-3.1
								C187.8,107.6,186.6,107.6,185.8,108z"
                              />
                              <path
                                id="XMLID_23_"
                                class="st3"
                                d="M195.4,113.2c-0.1-0.7-0.7-0.9-1.4-0.5l-55.4,31.9c-0.4,0.2-0.7,0.6-1,1l-8.2-4.7
								c0.2-0.4,0.6-0.8,0.9-1l55.4-31.9c0.8-0.4,2-0.4,2.8,0l5.5,3.1C194.7,111.5,195.3,112.4,195.4,113.2z"
                              />
                              <g>
                                <path
                                  class="st2"
                                  d="M194,112.7l-55.4,31.9c-0.8,0.4-1.4,1.5-1.4,2.4v30.9c0,0.9,0.6,1.2,1.4,0.8l55.4-31.9
									c0.8-0.4,1.4-1.5,1.4-2.4v-30.9C195.4,112.6,194.8,112.2,194,112.7z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <polygon
                        class="st14"
                        points="166.6,132.5 174.6,133.4 172.9,139 164.9,138.1 			"
                      />
                      <path
                        class="st14"
                        d="M166.8,149.5c0.3-1,0-1.9-0.7-2c-0.7-0.1-1.5,0.7-1.8,1.8c-0.3,0.9-0.1,1.6,0.3,1.9l-3.4,10.3l-0.3-13.4
				l3.7-8.5l7.5,0.8l-1.2,8.3l-8.7,12.8l3.2-10.3C165.4,151.1,166.6,150.4,166.8,149.5z"
                      />
                    </g>
                  </g>
                </g>
                <g id="ability">
                  <g id="Text_Box_1_">
                    <g id="_x7B__x7D__1_">
                      <g id="Speech_Bubble_1_">
                        <g id="Speach_Bubble_3_">
                          <g id="XMLID_16_">
                            <path
                              id="XMLID_18_"
                              class="st9"
                              d="M186.3,170.5l-53.9,31c-0.7,0.4-1.3,1.5-1.3,2.3V234c0,0.9,0.6,1.9,1.3,2.3l5.3,3
							c0.7,0.4,1.9,0.4,2.7,0l53.9-31c0.7-0.4,1.3-1.5,1.3-2.3v-30.1c0-0.9-0.6-1.9-1.3-2.3l-5.3-3
							C188.2,170.1,187,170.1,186.3,170.5z"
                            />
                            <path
                              id="XMLID_17_"
                              class="st3"
                              d="M195.6,175.6c-0.1-0.7-0.7-0.9-1.3-0.5l-53.9,31c-0.4,0.2-0.7,0.6-0.9,1l-8-4.6
							c0.2-0.4,0.6-0.7,0.9-1l53.9-31c0.7-0.4,1.9-0.4,2.7,0l5.3,3C195,173.9,195.5,174.8,195.6,175.6z"
                            />
                            <g>
                              <path
                                class="st2"
                                d="M194.3,175.1l-53.9,31c-0.7,0.4-1.3,1.5-1.3,2.3v30.1c0,0.9,0.6,1.2,1.3,0.8l53.9-31
								c0.7-0.4,1.3-1.5,1.3-2.3v-30.1C195.7,175,195.1,174.7,194.3,175.1z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="_x3C__x2F__x3E__1_">
                      <path
                        class="st14"
                        d="M153.2,212.8c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.7l7-11.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
				c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0.1,0.2,0.1,0.3v4.4c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.5-0.4,0.6l-3.6,5.5l3.6,1.7
				c0.1,0,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.7v3.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.3,0.3c0,0-0.1,0-0.1,0
				c0,0-0.1,0-0.1,0l-7-3.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.6V212.8z"
                      />
                      <path
                        class="st14"
                        d="M168.6,195.3c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.6l2.4-1.4c0.1-0.1,0.2-0.1,0.3,0
				c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2,0,0.3l-5.9,25.4c0,0.2-0.1,0.4-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.6l-2.3,1.3
				c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3L168.6,195.3z"
                      />
                      <path
                        class="st14"
                        d="M181.5,199.4c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.7l-7,11.4c0,0.1-0.1,0.1-0.1,0.1
				c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.1-0.3v-4.4c0-0.4,0.1-0.7,0.2-0.9c0.1-0.2,0.3-0.4,0.4-0.6
				l3.1-4.8l-3.1-1.2c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.7v-4.4c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.3
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l7,3.3c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.6V199.4z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function toProjects() {
  window.scrollTo({
    top: document.getElementById("projects").offsetTop,
    behavior: "smooth",
  });
}

export default Aboutme;
