import React, { Component } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import file from "../../assets/CV.pdf";
import logo from "../../assets/logo.png";
import "./NavigationBar.css";
import AnchorLink from "react-anchor-link-smooth-scroll";

class NavigationBar extends Component {
  // Handle Dark Mode state

  componentDidMount() {
    const KEY = "dark_mode";
    let isInDarkMode = localStorage.getItem(KEY) === "true";

    const toggleDarkMode = () => {
      if (isInDarkMode) {
        // console.log(toggle.classList);
        toggle.classList.add("on");
        document.body.classList.add("dark");
        document.getElementById("color-change-1").style.fill = "white";
        document.getElementById("color-change-2").style.fill = "white";
      } else {
        toggle.classList.remove("on");
        document.body.classList.remove("dark");
        document.getElementById("color-change-1").style.fill = "black";
        document.getElementById("color-change-2").style.fill = "black";
      }

      localStorage.setItem(KEY, isInDarkMode);
    };

    const toggle = document.getElementById("toggleDarkMode");
    toggle.addEventListener("click", function () {
      isInDarkMode = !isInDarkMode;
      toggleDarkMode();
    });
    toggleDarkMode();
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="xl"
        bg="light"
        variant="light"
        id="nav"
      >
        <Container>
          <Navbar.Brand href="./" className="logo">
            <img
              alt="My personal website. A good-looking portfolio I created to showcase my projects"
              className="logo_img"
              src={logo}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link className="nav-objects-spacing">
                <AnchorLink href="#about">about</AnchorLink>
              </Nav.Link>
              <Nav.Link className="nav-objects-spacing">
                <AnchorLink href="#projects">projects</AnchorLink>
              </Nav.Link>
              <Nav.Link className="nav-objects-spacing">
                <AnchorLink href="#tech-stack">tech-stack</AnchorLink>
              </Nav.Link>
              <Nav.Link className="nav-objects-spacing">
                <AnchorLink href="#contact">contact</AnchorLink>
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href={file} download="CV.pdf">
                <svg
                  width="30"
                  height="32"
                  viewBox="0 0 36 38"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.1 3L23 0.5L0 2.2L2.2 33L4.2 32.8V6.2H29.4V34.7L31.9 35L35.2 4.3L23.1 3Z"
                    fill="#010101"
                    id="color-change-1"
                  />
                  <path
                    d="M6.40002 8.39999V37.1H27.3V8.39999H6.40002ZM13.3 13.3C14.6 13.3 15.7 13.8 16.3 14.7L14.9 16C14.5 15.5 14 15.2 13.4 15.2C12.5 15.2 11.8 15.9 11.8 16.9C11.8 17.9 12.4 18.6 13.4 18.6C14 18.6 14.5 18.3 14.9 17.8L16.3 19.1C15.6 20 14.6 20.5 13.3 20.5C11.1 20.5 9.50002 19 9.50002 17C9.50002 14.7 11.1 13.3 13.3 13.3ZM23.8 32.6H9.80002V30.5H23.8V32.6ZM23.8 28.8H9.80002V26.7H23.8V28.8ZM23.8 25H9.80002V22.9H23.8V25ZM21.3 20.2H19.1L16.2 13.4H18.7L20.3 17.3L22 13.4H24.2L21.3 20.2Z"
                    fill="#010101"
                    id="color-change-2"
                  />
                </svg>
              </Nav.Link>
              <Nav.Item className="toggle-spacing">
                <div class="toggle" id="toggleDarkMode" ref="toggle">
                  <div class="toggle-track">
                    <span role="img" aria-label="moon">
                      🌙
                    </span>
                    <span role="img" aria-label="moon">
                      ☀️
                    </span>
                  </div>
                  <div class="toggle-button"></div>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavigationBar;
