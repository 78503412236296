import React from "react";
import projectData from "../../data/data";
import "./Projects.css";

const Projects = () => {
  const { web, data, python } = projectData;

  return (
    <section class="projects container" id="projects">
      <div className="projects-spacing"></div>
      <h4 className="font-style"> &lt;Projects&gt;</h4>
      <div class="col">
        <ul class="tabs">
          <li class="tab col s4">
            <a class="active" href="#web">
              <i class="fas fa-code"></i>{" "}
              <span className="responsive-text">Web Development</span>
            </a>
          </li>
          <li class="tab col s4">
            <a href="#data">
              <i class="fa fa-bar-chart" aria-hidden="true"></i>{" "}
              <span className="responsive-text">Data Analysis</span>
            </a>
          </li>
          <li class="tab col s4">
            <a href="#python">
              <i class="fab fa-python"></i>{" "}
              <span className="responsive-text">Python Engineering</span>
            </a>
          </li>
        </ul>
      </div>
      <br></br>

      {/* Web Development */}

      <div className="flex-container" id="web">
        {web.map((project) => (
          <div class="card flex-item">
            <div class="card-image">
              <img
                className="card-image-custom"
                alt={project.title}
                src={project.image}
              />
            </div>
            <div class="card-content">
              <span class="card-title text-darken-4">{project.title}</span>
              <p>{project.description}</p>
              <br></br>
              {project.technologies.map((technology) => (
                <span className="label">{technology}</span>
              ))}
            </div>
            <div class="card-action">
              {project.githubLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.githubLink}
                  >
                    <i class="fab fa-github fa-2x"></i>
                  </a>
                </div>
              ) : null}
              {project.websiteLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.websiteLink}
                  >
                    <i class="fas fa-globe fa-2x"></i>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      {/* Data Analytics */}

      <div className="flex-container" id="data">
        {data.map((project) => (
          <div class="card flex-item">
            <div class="card-image">
              <img
                className="card-image-custom"
                alt={project.title}
                src={project.image}
              />
            </div>
            <div class="card-content">
              <span class="card-title text-darken-4">{project.title}</span>
              <p>{project.description}</p>
              <br></br>
              {project.technologies.map((technology) => (
                <span className="label">{technology}</span>
              ))}
            </div>
            <div class="card-action">
              {project.githubLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.githubLink}
                  >
                    <i class="fab fa-github fa-2x"></i>
                  </a>
                </div>
              ) : null}
              {project.notebookLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.note}
                  >
                    <i class="fas fa-book-open fa-2x"></i>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      {/* Python Engineering */}

      <div className="flex-container" id="python">
        {python.map((project) => (
          <div class="card flex-item">
            <div class="card-image">
              <img
                className="card-image-custom"
                alt={project.title}
                src={project.image}
              />
            </div>
            <div class="card-content">
              <span class="card-title text-darken-4">{project.title}</span>
              <p>{project.description}</p>
              {project.technologies
                ? project.technologies.map((technology) => (
                    <span className="label">{technology}</span>
                  ))
                : null}
            </div>
            <div class="card-action">
              {project.githubLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.githubLink}
                  >
                    <i class="fab fa-github fa-2x"></i>
                  </a>
                </div>
              ) : null}
              {project.notebookLink ? (
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={project.notebookLink}
                  >
                    <i class="fas fa-book-open fa-2x"></i>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      <h4 className="font-style"> &lt;\Projects&gt;</h4>
    </section>
  );
};

export default Projects;
