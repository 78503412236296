import React from "react";
import { Container, Row } from "react-bootstrap";
import "./Technologies.css";
import react from "../../assets/react.png";
import angular from "../../assets/angular.png";
import sass from "../../assets/sass.png";
import jasmine from "../../assets/jasmine.png";
import typescript from "../../assets/typescript.png";
import css3 from "../../assets/css3.png";
import html5 from "../../assets/html5.png";
import django from "../../assets/django.png";
import postgresql from "../../assets/postgresql.png";
import node from "../../assets/node.png";
import firebase from "../../assets/firebase.png";
import express from "../../assets/express.png";
import mongo from "../../assets/mongo.png";
import js from "../../assets/js.png";
import d3js from "../../assets/d3js.png";
import wordpress from "../../assets/wordpress.png";
import excel from "../../assets/excel.png";
import matplotlib from "../../assets/matplotlib.png";
import numpy from "../../assets/numpy.png";
import rapidminer from "../../assets/rapidminer.png";
import python from "../../assets/python.png";
import scikitlearn from "../../assets/scikitlearn.png";
import pandas from "../../assets/pandas.png";
import powerBI from "../../assets/powerBI.png";
import ai from "../../assets/ai.png";
import ps from "../../assets/ps.png";
import ae from "../../assets/ae.png";
import pr from "../../assets/pr.png";
import git from "../../assets/git.png";
import github from "../../assets/github.png";
import figma from "../../assets/figma.png";
import terminal from "../../assets/terminal.png";
import vscode from "../../assets/vscode.png";
import ScrollAnimation from "react-animate-on-scroll";

const Technologies = () => {
  return (
    <section class="tech-stack" id="tech-stack">
      <div className="projects-spacing"></div>
      <Container>
        <Row>
          <h4 className="font-style"> &lt;Tech-Stack&gt;</h4>
        </Row>

        <Row>
          <div id="flex-container">
            <ScrollAnimation animateIn="slideInLeft">
              <div className="web">
                <h5 className="font-style">Web Development</h5>
                <div className="skills-flex-container">
                  <div className="skill-container">
                    <img alt="js img" class="skill-img" src={js}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                    >
                      <div class="overlay">
                        <div class="text">JavaScript ES6</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="typescript img"
                      class="skill-img"
                      src={typescript}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.typescriptlang.org/"
                    >
                      <div class="overlay">
                        <div class="text">Typescript</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="react img" class="skill-img" src={react}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://reactjs.org/"
                    >
                      <div class="overlay">
                        <div class="text">React</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="angular img"
                      class="skill-img"
                      src={angular}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://angular.io/"
                    >
                      <div class="overlay">
                        <div class="text">Angular</div>
                      </div>
                    </a>
                  </div>{" "}
                  <div className="skill-container">
                    <img alt="html5 img" class="skill-img" src={html5}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5"
                    >
                      <div class="overlay">
                        <div class="text">HTML5</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="css3 img" class="skill-img" src={css3}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                    >
                      <div class="overlay">
                        <div class="text">CSS3</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="sass img" class="skill-img" src={sass}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://sass-lang.com/"
                    >
                      <div class="overlay">
                        <div class="text">Sass</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="jasmine img"
                      class="skill-img"
                      src={jasmine}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://jasmine.github.io/"
                    >
                      <div class="overlay">
                        <div class="text">Jasmine</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="d3js img" class="skill-img" src={d3js}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://d3js.org/"
                    >
                      <div class="overlay">
                        <div class="text">D3js</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="wordpress img"
                      class="skill-img"
                      src={wordpress}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://wordpress.com/"
                    >
                      <div class="overlay">
                        <div class="text">Wordpress</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="node img" class="skill-img" src={node}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://nodejs.org/en/"
                    >
                      <div class="overlay">
                        <div class="text">Node JS</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="firebase img"
                      class="skill-img"
                      src={firebase}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://firebase.google.com/"
                    >
                      <div class="overlay">
                        <div class="text">Firebase</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="postgresql img"
                      class="skill-img"
                      src={postgresql}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.postgresql.org/"
                    >
                      <div class="overlay">
                        <div class="text">Postgresql</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="django img" class="skill-img" src={django}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.djangoproject.com/"
                    >
                      <div class="overlay">
                        <div class="text">Django</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="express img"
                      class="skill-img"
                      src={express}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://expressjs.com/"
                    >
                      <div class="overlay">
                        <div class="text">Express</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="mogno db img" class="skill-img" src={mongo}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.mongodb.com/"
                    >
                      <div class="overlay">
                        <div class="text">MongoDB</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="slideInRight">
              <div className="data">
                <h5 className="font-style">Data Analysis</h5>
                <div className="skills-flex-container">
                  <div className="skill-container">
                    <img alt="python img" class="skill-img" src={python}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.python.org/"
                    >
                      <div class="overlay">
                        <div class="text">Python 3</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="pandas img" class="skill-img" src={pandas}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="pandas.pydata.org/"
                    >
                      <div class="overlay">
                        <div class="text">Pandas</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="numpy img" class="skill-img" src={numpy}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://numpy.org/"
                    >
                      <div class="overlay">
                        <div class="text">Numpy</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="matplotlib img"
                      class="skill-img"
                      src={matplotlib}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://matplotlib.org/"
                    >
                      <div class="overlay">
                        <div class="text">Matplotlib</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="scikitlearn img"
                      class="skill-img"
                      src={scikitlearn}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://scikit-learn.org/"
                    >
                      <div class="overlay">
                        <div class="text">Scikitlearn</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img alt="excel img" class="skill-img" src={excel}></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.microsoft.com/en-US/microsoft-365/excel"
                    >
                      <div class="overlay">
                        <div class="text">Excel</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="rapidminer img"
                      class="skill-img"
                      src={rapidminer}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://rapidminer.com/"
                    >
                      <div class="overlay">
                        <div class="text">RapidMiner</div>
                      </div>
                    </a>
                  </div>
                  <div className="skill-container">
                    <img
                      alt="powerBI img"
                      class="skill-img"
                      src={powerBI}
                    ></img>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://powerbi.microsoft.com/en-us/"
                    >
                      <div class="overlay">
                        <div class="text">PowerBI</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </Row>

        <Row>
          <ScrollAnimation animateIn="slideInUp" className="full-width">
            <div className="other">
              <h5 className="font-style">
                Other tools I use for development and design
              </h5>
              <div className="skills-other-flex-container">
                <div className="skill-other-container">
                  <img alt="Photoshop img" class="skill-img" src={ps}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.adobe.com/products/photoshop.html"
                  >
                    <div class="overlay">
                      <div class="text">Photoshop</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img
                    alt="Adobe Illustration img"
                    class="skill-img"
                    src={ai}
                  ></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.adobe.com/products/illustrator.html"
                  >
                    <div class="overlay">
                      <div class="text">Illustrator</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img alt="Premiere pro img" class="skill-img" src={pr}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.adobe.com/products/premiere.html"
                  >
                    <div class="overlay">
                      <div class="text">Premiere Pro</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img alt="After Effect img" class="skill-img" src={ae}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.adobe.com/products/aftereffects.html"
                  >
                    <div class="overlay">
                      <div class="text">After Effects</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img alt="Figma img" class="skill-img" src={figma}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.figma.com/"
                  >
                    <div class="overlay">
                      <div class="text">Figma</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img alt="Git img" class="skill-img" src={git}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://git-scm.com/"
                  >
                    <div class="overlay">
                      <div class="text">Git</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img alt="Github img" class="skill-img" src={github}></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://github.com"
                  >
                    <div class="overlay">
                      <div class="text">Github</div>
                    </div>
                  </a>
                </div>
                <div className="skill-other-container">
                  <img
                    alt="terminal img"
                    class="skill-img"
                    src={terminal}
                  ></img>
                  <div class="overlay">
                    <div class="text">Terminal</div>
                  </div>
                </div>
                <div className="skill-other-container">
                  <img
                    alt="Visual Studio code img"
                    class="skill-img"
                    src={vscode}
                  ></img>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://code.visualstudio.com/"
                  >
                    <div class="overlay">
                      <div class="text">VS Code</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Row>

        <Row>
          <h4 className="font-style"> &lt;\Tech-Stack&gt;</h4>
        </Row>
      </Container>
    </section>
  );
};

export default Technologies;
