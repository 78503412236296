import { gsap } from "gsap";
import { Elastic } from "gsap";

const animate = () => {
  // Animation
  gsap.set("#Window", { scale: 0, transformOrigin: "0% 50%" });
  gsap.set("#Head", { transformOrigin: "0% 100%" });
  gsap.set("#Speech_Bubble", { scale: 0, transformOrigin: "50% 50%" });
  gsap.set("#left_leaf", { transformOrigin: "50% bottom" });
  gsap.set("#right_leaf", { transformOrigin: "50% bottom" });

  gsap.to("#left_leaf", 1, {
    rotate: 3,
    yoyo: true,
    yoyoEase: true,
    repeat: -1,
  });
  gsap.to("#right_leaf", 1, {
    rotate: 3,
    yoyo: true,
    yoyoEase: true,
    repeat: -1,
    delay: 0.5,
  });

  var tl = new gsap.timeline({
    defaults: { ease: Elastic.easeOut.config(1, 0.3) },
  });
  var tl_head = new gsap.timeline({ repeat: -1 });

  tl_head
    .to("#Head", 0.8, {
      rotate: 3,
      yoyo: true,
      delay: 1,
    })
    .to("#Head", 0.9, {
      rotate: 0,
      yoyo: true,
      delay: 3,
    });

  var tl_left_arm = new gsap.timeline({ repeat: -1 });
  tl_left_arm
    .to("#left_arm", 1, {
      x: 10,
      y: 5,
    })
    .to("#left_arm", 0.6, {
      x: -5,
      y: -15,
    })
    .to("#left_arm", 1.2, {
      x: -5,
      y: 5,
    })
    .to("#left_arm", 0.5, {
      x: 0,
      y: 0,
    });

  var tl_right_arm = new gsap.timeline({ repeat: -1 });
  tl_right_arm
    .to("#right_arm", 1, {
      rotate: 5,
    })
    .to("#right_arm", 0.6, {
      rotate: -5,
    })
    .to("#right_arm", 0.8, {
      rotate: 0,
    });

  var tl_window = new gsap.timeline();
  tl_window.to("#Window", {
    scale: 1,
    opacity: 1,
    ease: "expo.out",
    duration: 1.5,
    stagger: 0.5,
  });

  var tl_bubble = new gsap.timeline();
  tl_bubble.to("#Speech_Bubble", {
    opacity: 1,
    scale: 1,
    ease: Elastic.easeOut.config(1, 0.75),
    duration: 2,
  });

  var tl_abilities = new gsap.timeline();
  tl_abilities.staggerFrom("#ability", 1, { y: "+=100", opacity: 0 }, 0.5);
  //final timeline
  tl.add(tl_abilities)
    .add(tl_bubble)
    .add([tl_head, tl_left_arm, tl_right_arm], "-=1")
    .add(tl_window);
};

export default animate;
