const projectData = {
  web: [
    {
      title: "Marsian",
      image: "https://i.ibb.co/YN8dp8Q/marsian.png",
      description: "A booking platform for humans relocating to Mars",
      technologies: ["Vue.js", "Gsap", "Three.js", "Jest"],
      websiteLink: "https://flamboyant-shannon-4e0c1f.netlify.app",
      githubLink: "https://github.com/ionpetro/booking-platform-client",
    },
    {
      title: "Arhs Cinema",
      image: "https://i.ibb.co/ZXBJRQM/movies.png",
      description:
        "A movies app where you can add movies and select your favorite ones.",
      technologies: ["Angular", "Typescript", "NodeJS", "Firebase"],
      websiteLink: "https://arhs-cinema.web.app/",
      githubLink: "https://github.com/ionpetro/Arhs-Cinema-App",
    },
    {
      title: "Blockpool",
      image: "https://i.ibb.co/KNj787w/blockpool.png",
      description:
        "H2020 EU project website on the sector of Blockchain. More than 20,000 visitors.",
      technologies: ["HTML5", "Css3", "Wordpress", "JavaScript"],
      websiteLink: "https://blockpool.eu/",
      githubLink: "",
    },
    {
      title: "So cool notes",
      image:
        "https://raw.githubusercontent.com/ionpetro/notes-app/master/src/assets/ss.png",
      description: "A note taking app that helps you organize your work",
      technologies: ["Angular", "Angular Animations", "Scss", "Bulma"],
      websiteLink: "https://so-cool-notes.web.app/",
      githubLink: "https://github.com/ionpetro/notes-app",
    },
    {
      title: "POS data vis",
      image: "https://i.ibb.co/4JPgx4H/posdata.png",
      description: "A POS Data Visualization on Basket Data Clustering",
      technologies: ["D3.js", "Html5", "Css3", "Rapid Miner"],
      websiteLink: "https://www.ionpetro.com/pos-vis/",
      githubLink: "https://github.com/ionpetro/POS-data-visualisation",
    },
    {
      title: "StartupRank",
      image: "https://i.ibb.co/0BFpnXq/startuprank.png",
      description:
        "A place where you find your favourite Greek startup and you review it",
      technologies: ["Html5", "Css3", "Django", "Postgresql"],
      websiteLink: "https://startuprank.herokuapp.com/",
      githubLink: "https://github.com/ionpetro/StartupRank",
    },
  ],
  data: [
    {
      title: "Civil Resistance",
      image: "https://i.ibb.co/NZZB6bk/civilres.png",
      description:
        "Explaining and replicating the findings of Why Civil Resistance Works book",
      technologies: ["Pandas", "MatPlotLib,", "Numpy", "SkitLearn"],
      notebookLink:
        "https://nbviewer.jupyter.org/github/ionpetro/Exploring-Why-Civil-Resistance-Works/blob/master/Why_Civil_Resistance_Works.ipynb",
      githubLink:
        "https://github.com/ionpetro/Exploring-Why-Civil-Resistance-Works",
    },
    {
      title: "MEPs tweets clustering",
      image: "https://i.ibb.co/nDMJSFC/meps.png",
      description:
        "Investigating a dataset of tweets made by Members of the European Parliament",
      technologies: ["Tweepy", "Pandas", "Matplotlib", "Numpy", "Nltk"],
      notebookLink:
        "https://nbviewer.jupyter.org/github/ionpetro/Clustering-and-Classification-of-MEPs-Tweets/blob/master/src/European%20Parliament%20Twitter%20activities%20MEP%201.0.ipynb",
      githubLink:
        "https://github.com/ionpetro/Clustering-and-Classification-of-MEPs-Tweets",
    },
    {
      title: "US flight data",
      image: "https://i.ibb.co/jhPz4gw/usflight.png",
      description:
        "Investigating the Airline On-Time Performance Data of the United States of America.",
      technologies: ["Pandas", "Matplotlib", "Seaborn", "BeautifulSoup"],
      notebookLink:
        "https://github.com/ionpetro/Exploring-US-Flights-Data/blob/master/Exploring%20US%20Flights%20Data.ipynb",
      githubLink: "https://github.com/ionpetro/Exploring-US-Flights-Data",
    },
  ],
  python: [
    {
      title: "Jarvis",
      image: "https://i.ibb.co/Y7vsK54/jarvis.png",
      description:
        "My first Open Source Contribution to a 1,7⭐ project. Award winning contribution as 'best in class'.",
      notebookLink:
        "https://github.com/ionpetro/Software-Engineering-in-Practice-Final-Report/blob/master/First_OS_contribution.pdf",
      githubLink:
        "https://github.com/sukeesh/Jarvis/commit/7d8aafd7e7c94ecc0eab2a09fa6484ae599606b8",
    },
    {
      title: "Web Crawlers",
      image: "https://i.ibb.co/NCbtg4T/scraping.png",
      description:
        "Scraping the web using python's library BeautifulSoup to retrieve datasets that I use for further analysis.",
      notebookLink: "https://github.com/ionpetro/Breaking-Bad-Deaths-Crowler",
      githubLink:
        "https://github.com/ionpetro/Web-Crawler-for-IMDB-Top-Rated-Movies",
    },
    {
      title: "kMST implementation",
      image: "https://i.ibb.co/G5GdjSC/kmst.png",
      description:
        "Implementing the kMST algorithm as a bonus assignment for Algorithms and Data Structures course",
      notebookLink: "https://arxiv.org/pdf/math/9409222.pdf",
      githubLink:
        "https://github.com/ionpetro/Implementation-of-kMST-algorithm-for-2D-points",
    },
  ],
};

export default projectData;
